//
// COOKIE
//

#sliding-popup {
  bottom: 0;
  transform: translateY(100%);
  transition: transform .5s ease;
  .btRegular;
  &.sliding-popup--open {
    transform: translateY(0);
    .eu-cookie-withdraw-tab {
      transform: translate(0, -100%);
    }
  }
  &.sliding-popup--closed {
    transform: translateY(100%);
    .eu-cookie-withdraw-tab {
      transform: translate(0, -1em);
    }
  }
}

#sliding-popup.sliding-popup-bottom, #sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-banner, .eu-cookie-withdraw-tab {
  background-color: @black;
}

#sliding-popup .popup-content {
  padding: 0 1rem;
  max-width: 100%;
  a {
    color: @white;
  }
  #popup-text {
    font-weight: normal;
    margin-top: 14px;
    p {
      font-size: 0.88888889rem;
      line-height: 1.5em;
      font-weight: normal;
    }
    .find-more-button {
      font-size: 0.88888889rem;
      line-height: 1.5em;
      font-weight: normal;
    }
  }
  .eu-cookie-compliance-category {
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .eu-cookie-compliance-category > div:first-child {
    position: relative;
    label {
      .btBold;
      padding: (4/16)*1rem 0 (4/16)*1rem (33/18)*1rem;
      &:hover {
        cursor: pointer;
      }
    }
    label::before,
    label::after {
      content: "";
      display: block;
      inset-inline-start: 0;
      margin: 0;
      position: absolute;
      top: (5/18)*1rem;
      transition-duration: 0.15s;
      transition-timing-function: ease-in-out;
    }
    label::before {
      background: @white;
      border: 1px solid rgba(7, 0, 104, 0.25);
      border-radius: (4/18)*1rem;
      box-shadow: none;
      height: (24/18)*1rem;
      margin: 0;
      transition-property: background-color, border-color, box-shadow;
      width: (24/18)*1rem;
      z-index: 3;

    }
    label::after {
      background: transparent center center no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg focusable='false' aria-hidden='true' width='32px' height='32px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline stroke='%2322202b' stroke-width='5' points='5 16 13.6086957 24 27 8'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E%0A");
      background-size: cover;
      height: (18/18)*1rem;
      inset-inline-start: (3/18)*1rem;
      opacity: 0;
      inset-block-start: (7/18)*1rem;
      transition-property: opacity;
      width: (18/18)*1rem;
      z-index: 4;
    }
    input[type="checkbox"] {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
      &:focus + label::before {
        outline: none;
      }
      &:focus-visible + label::before {
        outline: 1px solid #5e9ed6;
        outline: 5px auto -webkit-focus-ring-color;
      }
      &:checked + label::before {
        border: 1px solid rgba(7, 0, 104, 0.5);
        box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
      }
      &:checked + label::after {
        opacity: 1;
      }
    }
  }
  .eu-cookie-compliance-category-description {
    font-size: 0.88888889rem;
    line-height: 1.5em;
    font-weight: normal;
    padding-left: (33/18)*1rem;
  }
  .eu-cookie-compliance-save-preferences-button {
    .button;
    .button.tertiary;
    .button.small;
    background-image: none;
    border: none;
    box-shadow: none;
    text-shadow: none;
  }
  #popup-buttons {
    .agree-button {
      .button;
      .button.tertiary;
      .button.small;
      background-image: none;
      border: none;
      box-shadow: none;
      text-shadow: none;
    }
    .decline-button,
    .eu-cookie-withdraw-button {
      .button;
      .button.small;
      background-image: none;
      border: none;
      box-shadow: none;
      text-shadow: none;
    }
  }
  @media (min-width: 601px) {
    margin: 0 auto;
    max-width: 1200px;
    padding: 1rem;
    #popup-text {
      margin-top: 0;
      max-width: none;
      padding-right: 1rem;
    }
    #popup-buttons {
      max-width: none;
    }
  }
  @media (min-width: 992px) {
    #popup-buttons {
      flex-shrink: 0;
    }
  }
}

// COOKIE BLOCKER
.cookie-content-blocker-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}
.cookie-content-blocker__message-wrapper {
  padding: 30px;
  text-align: center;
  button {
    border: none;
  }
}

// COOKIE BAR WITHDRAW TAB
.eu-cookie-withdraw-tab {
  border: none;
  .btBold;
  font-size: (14/18)*1rem;
  left: 6em;
  padding: 0.25em 0.75em;
  text-transform: uppercase;
  z-index: 4;
  @media (min-width: 768px) {
    left: 8em;
  }
}
