//
// LISTING (Cases)
//

.listing-cases {
  margin: 0 auto;
  max-width: 1600px;
  //padding-left: 10px;
  //padding-right: 10px;
  .list-item {
    //padding: 10px;
  }
  a {
    display: block;
    position: relative;
    text-decoration: none;
    &:hover {
      .image {
        &::after {
          .opacity(0.6);
        }
        img {
          //transform-origin: center bottom;
          //.scale(1.06);
          .scale(1.02)
        }
      }
      .info {
        h2 {
          .translate3d(0, -15px, 0);
        }
        span {
          .opacity(1);
          .translate3d(0, 0, 0);
        }
      }
    }
  }
  // Visual
  .image {
    overflow: hidden;
    position: relative;
    .translate3d(0, 0, 0);
    &::after {
      background-color: transparent;
      background-image: url('../images/blur-cases.png');
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
      .opacity(0.5);
      .transition(opacity .7s @ease);
    }
    img {
      backface-visibility: hidden;
      display: block;
      height: auto;
      max-width: 100%;
      position: relative;
      width: 100%;
      z-index: 1;
      transform-origin: center top;
      .scale(1.08);
      .transition(all .7s @ease);
    }
  }
  // Info
  .info {
    bottom: 9%;
    left: 6%;
    padding-right: 20px;
    position: absolute;
    width: 94%;
    h2 {
      color: @white;
      .btBlack;
      font-size: 1.4rem;
      line-height: 1.2em;
      .translate3d(0, 30px, 0);
      .transition(all .7s @ease);
      .touchevents & {
        .translate3d(0, 0, 0);
      }
      @media (max-width: 399px) {
        .hyphenate;
      }
    }
    span {
      color: @white;
      display: block;
      .btBold;
      font-size: 0.75rem;
      line-height: 1.2em;
      text-transform: uppercase;
      transform-origin: 50% 0%;
      .opacity(0);
      .transition(all .7s @ease);
      .translate3d(0, 10px, 0);
      .touchevents & {
        display: none;
      }
    }
  }
  .awards {
    left: 6%;
    position: absolute;
    top: 10px;
    width: 94%;
    .opacity(0);
      -webkit-animation: fadeInAndMove 1.5s ease-in-out;
      -moz-animation: fadeInAndMove 1.5s ease-in-out ;
      animation: fadeInAndMove 1.5s ease-in-out;
      -webkit-animation-iteration-count: 1;
      -moz-animation-iteration-count: 1;
      animation-iteration-count: 1;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-delay: 1s;
      -moz-animation-delay: 1s;
      animation-delay: 1s;
    img {
      height: 100px;
      width: auto;
      &.award-splash {
        .translate(-11.6666666667%, 0);
      }
    }
  }
  .has-award {
    .image {
      &::after {
        background-image: url('../images/blur-cases-award.png');
      }
    }
  }
}

@media (min-width: @breakpointMobileSmall) {
  .listing-cases {
    .info {
      h2 {
        font-size: 1.6666666667rem;
      }
      span {
        font-size: 0.8333333333rem;
      }
    }
    .awards {
      img {
        height: 120px;
      }
    }
  }
}

@media (min-width: 600px) {
  .listing-cases {
    .awards {
      top: 20px;
      img {
        height: 140px;
      }
    }
  }
}

@media (min-width: 900px) {
  .listing-cases {
    .clearfix;
    .list-item {
      float: left;
      width: 50%;
    }
  }
}

@media (min-width: 1200px) {
  .listing-cases {
    .awards {
      top: 25px;
      img {
        height: 168px;
      }
    }
  }
}
