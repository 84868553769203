//
// GRID
//

@gridMaxWidth: 960px;

#container {
  position: relative;
  z-index: 100;
  &.active {
    left: 0;
  }
  .lt-ie9 & {
    min-width: 768px;
  }
  &::after,
  &::before {
    background: @color-secondary;
    content: '';
    display: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 999;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleX(1) translateZ(0);
    -ms-transform: scaleX(1);
    transform: scaleX(1) translateZ(0);
    .no-js & {
      display: none;
    }
  }
  &::before {
    .front & {
      display: none;
    }
  }
  &::after {
    left: auto;
    right: 0;
    -webkit-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleX(-1) translateZ(0);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1) translateZ(0);
  }
  &.is-ready::before {
    -webkit-transition: 1s .35s @ease;
    transition: 1s .35s @ease;
    -ms-transform: scaleX(0);
    -webkit-transform: scaleX(0) translateZ(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0) translateZ(0);
    visibility: hidden;
  }
  &.is-leaving::before {
    -webkit-transform: scaleX(0) translateZ(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0) translateZ(0);
  }
  &.is-leaving::after {
    -webkit-transition: 1s .35s @ease;
    transition: 1s .35s @ease;
    visibility:visible;
    -ms-transform:scaleX(1);
    -webkit-transform:scaleX(1) translateZ(0);
    -ms-transform:scaleX(1);
    transform:scaleX(1) translateZ(0);
  }
}

.widthWrapper {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  width: auto;
  padding: 0 15px;
  .clearfix;
}
.articleHeader,
.articleContent {
  p, h1, h2, h3, h4, h5, h6, ul, ol, blockquote,
  .normalWidth {
    margin: 0 auto;
    max-width: @contentDefaultWidth;
  }
}
section {
  .mediumWidth,
  &.mediumWidth,
  &.teamSingle {
    margin: 0 auto;
    max-width: 875px;
  }
  &.teamOverview {
    max-width: none;
  }
}
section {
  .fullWidth,
  &.fullWidth {
    max-width: 100%;
  }
}
section {
  &.share,
  &.meta,
  &.workAtBetawerk {
    margin: 0 auto;
    max-width: @contentDefaultWidth;
    padding: 0 15px;
  }
}
section.workAtBetawerk {
  margin: 10px auto;
  padding: 0;
}

.caseSlider .rsGCaption .title,
.caseSlider .rsGCaption .subtitle {
  padding: 0 15px;
}

// COLS
.colsWrapper {
  margin-bottom: 2em;
  overflow: hidden;
  &.home {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.colsOneThird {
  float: none;
  margin: 0 0 20px 0;
  position: relative;
  width: 100%;
  strong {
    .btBold;
  }
  p, ul {
    margin-bottom: 0.6em;
  }
  h2 {
    .btBold;
    margin-bottom: 10px;
  }
  .image {
    img {
      height: auto;
      max-width: 100%;
    }
    &.drupalLogo {
      max-width: 130px;
    }
  }
}

@media (min-width: @breakpointMobileSmall) {
  .widthWrapper {
    padding: 0 30px;
  }

  section {
    &.share,
    &.meta,
    &.workAtBetawerk {
      margin: 0 auto;
      padding: 0 30px;
    }
  }
}

@media (min-width: @breakpointMobile) {
  .colsOneThird {
    margin: 0 3% 0 2%;
    float: left;
    width: 28.3%;
  }
}