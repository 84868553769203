//
// CASES
// Overview and single
//

// CASES OVERVIEW & HOME
section.cases {
  background-color: @white;
}

// CASE SINGLE
.node-type-case {
  #content {
    padding-bottom: 50px;
    padding-top: 0;
  }
}

#caseMainImage {
  background-color: @color-secondary;
  display: block;
  height: 67vh;
  position: relative;
  width: 100%;
  z-index: -1;
  &:after {
    background: url('../images/bg-case.png') no-repeat 0 0 transparent;
    background-size: cover;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    .opacity(0);
    -webkit-animation: fadeIn 1.5s ease-in-out;
    -moz-animation: fadeIn 1.5s ease-in-out ;
    animation: fadeIn 1.5s ease-in-out;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    animation-delay: 2s;
  }
  .caseMainImage-inner {
    display: block;
    height: 70vh;
    position: relative;
    width: 100%;
  }
  #waypoint-trigger {
    top: 80px;
    position: absolute;
  }
  .widthWrapper {
    max-width: 1200px;
    z-index: 2;
    .touchevents & {
      position: static;
    }
  }
  .quote {
    margin: 0 auto;
  }
  blockquote {
    color: @white;
    .btBlack;
    font-size: 1.6rem;
    line-height: 1.4285714286em;
    max-width: 250px;
    .opacity(0);
    top: 26vh;
    position: fixed;
    -webkit-animation: fadeInAndMove 2.5s @ease;
    -moz-animation: fadeInAndMove 2.5s @ease ;
    animation: fadeInAndMove 2.5s @ease;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    animation-delay: 1.5s;
    .touchevents & {
      position: absolute;
      z-index: 99;
    }
  }
  img {
    .opacity(0);
    -webkit-animation: fadeIn 1.5s ease-in-out;
    -moz-animation: fadeIn 1.5s ease-in-out ;
    animation: fadeIn 1.5s ease-in-out;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
}

@media (min-width: 500px) {
  #caseMainImage {
    height: 79vh;
    .caseMainImage-inner {
      height: 82vh;
    }
  }
}

@media (min-width: 600px) {
  #caseMainImage {
    blockquote {
      font-size: 1.9444444444rem;
      max-width: 400px;
      top: 31%;
    }
  }
}