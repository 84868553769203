//
// CONTENT SINGLE PAGES
//

// HiLite
.hiLite {
   padding: 2.5em 1em 1em;
   margin: 3em auto 3em;
   background-color: #e6fa37;
   max-width: 800px;
   box-shadow: 0px 30px 30px -35px rgba(0,0,0,0.4);
}

.features.hasBorderTop {
   border-top: 2px solid rgba(0,0,0,0.07);
   margin-top: 1.3em !important;
   padding-top: 1em;
}
ul.features li {
   line-height: 1.2em !important;
   list-style: none;
   margin-left: 0 !important;
   padding: 0.6em 0 0.6em 2em !important;
   position: relative;
    &::before {
      background: transparent url('../images/svg/betawerk_check.svg') center center no-repeat;
      content: '';
      display: block;
      height: 15px;
      left: 0;
      position: absolute;
      top: 13px;
      width: 19px;
    }
}

// Diensten
.services {
  .colsOneThird {
    border-top: 2px solid rgba(0,0,0,0.07);
    margin-top: 1em !important;
    padding-top: 2.2em;
    &:last-child {
      ul {
        margin-bottom: 0;
      }
    }
    .image {
      margin-bottom: 10px;
      width: 100%;
    }
    .text {
      padding-left: 0;
      p {
        margin: 0 0 1.2em 0;
      }
    }
    h2, p, ul {
      padding: 0;
    }
    h2 {
      font-size: 1.2222222222rem;
      margin: 1em 0;
    }
  }
}

// Team
.teamSingle {
  .teamMember {
    margin: 0 0 20px 0;
    overflow: hidden;
    border-bottom: 1px solid #EEEEEE;
    padding-bottom: 20px;
    .image {
      float: none;
      width: 100%;
      margin-right: 3%;
      img {
        display: block;
        height: auto;
        max-width: 100%;
      }
    }
    .text {
      font-size: 0.8333333333rem;
      line-height: 1.3333333333em;
      overflow: hidden;
      padding: 15px 0 0;
      word-wrap: break-word;
      h1 {
        font-size: 1.0555555556rem;
        .btBold;
      }
      p {
        margin-bottom: 1em;
        }
      .socialLinks {
        font-size: 90%;
      }
    }
  }
}

// Team listing
ul.team {
  overflow: hidden;
  .spacing();
  li {
    .inline-block();
    list-style: none;
    margin: 0 0 10px;
    padding: 0;
    vertical-align: top;
    width: 100%;
    .spacing-normal();
    a {
      font-size: 0.8333333333rem;
      line-height: 1.2666666667em;
      color: @textColor;
      display: block;
      overflow: hidden;
      text-decoration: none;
      .transition(background-color .35s @ease);
      &:hover {
        background-color: #eee;
      }
    }
    .image {
      float: left;
      width: 30%;
      margin-right: 3%;
    }
    img {
      display: block;
      height: auto;
      max-width: 100%;
    }
    .info {
      padding: 1em 0 0;
    }
    h3 {
      font-weight: bold;
      line-height: 1.5em;
      padding-top: 2px;
    }
    p {
      line-height: 1.5em;
    }
  }
}

.workAtBetawerk {
  text-align: center;
  .inner {
    padding: 20px;
  }
  p {
    line-height: 1.1666666667em;
    margin-bottom: 1.2em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.related {
  display: none;
}

ul.has-checks {
  background-color: #f6f6f4;
  list-style: none;
  padding: 27px 20px 28px;
  li {
    background-image: url('../images/icon-check.png');
    background-position: 0 10px;
    background-repeat: no-repeat;
    margin-left: 0 !important;
    padding-left: 34px !important;
    list-style: none;
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      background-image: url('../images/icon-check@2x.png');
      background-size: 19px 14px;
    }
  }
}

@media (min-width: @breakpointMobileSmall) {
  .teamSingle {
    .teamMember {
      margin: 0 3% 20px 2%;
      .image {
        float: left;
        width: 48.5%;
      }
      .text {
        padding: 5px 0 0;
      }
    }
  }

  ul.team {
    li {
      margin: 0 2% 20px;
      width: 45%;
      .image {
        float: none;
        margin-right: 0;
        width: 100%;
      }
    }
  }

  ul.has-checks {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: @breakpointMobile) {
  // Team listing
  ul.team {
    li {
      margin: 0 3% 2em 3%;
      width: 27%;
    }
  }
}
