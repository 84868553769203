//
// MIXINS
//



// Drop shadows
.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow;
     -moz-box-shadow: @shadow;
          box-shadow: @shadow;
}

// Transitions
.transition(@transition) {
  -webkit-transition: @transition;
     -moz-transition: @transition;
       -o-transition: @transition;
          transition: @transition;
}
.transition-delay(@transition-delay) {
  -webkit-transition-delay: @transition-delay;
     -moz-transition-delay: @transition-delay;
       -o-transition-delay: @transition-delay;
          transition-delay: @transition-delay;
}

// Transformations
.rotate(@degrees) {
  -webkit-transform: rotate(@degrees);
     -moz-transform: rotate(@degrees);
      -ms-transform: rotate(@degrees);
       -o-transform: rotate(@degrees);
          transform: rotate(@degrees);
}
.scale(@ratio) {
  -webkit-transform: scale(@ratio);
     -moz-transform: scale(@ratio);
      -ms-transform: scale(@ratio);
       -o-transform: scale(@ratio);
          transform: scale(@ratio);
}
.translate(@x, @y) {
  -webkit-transform: translate(@x, @y);
     -moz-transform: translate(@x, @y);
      -ms-transform: translate(@x, @y);
       -o-transform: translate(@x, @y);
          transform: translate(@x, @y);
}
.translateZ(@z) {
  -webkit-transform: translateZ(@z);
     -moz-transform: translateZ(@z);
      -ms-transform: translateZ(@z);
       -o-transform: translateZ(@z);
          transform: translateZ(@z);
}
.translate3d(@x, @y, @z) {
  -webkit-transform: translate3d(@x, @y, @z);
     -moz-transform: translate3d(@x, @y, @z);
      -ms-transform: translate3d(@x, @y, @z);
       -o-transform: translate3d(@x, @y, @z);
          transform: translate3d(@x, @y, @z);
}
.skew(@x, @y) {
  -webkit-transform: skew(@x, @y);
     -moz-transform: skew(@x, @y);
      -ms-transform: skewX(@x) skewY(@y); // See https://github.com/twitter/bootstrap/issues/4885
       -o-transform: skew(@x, @y);
          transform: skew(@x, @y);
  -webkit-backface-visibility: hidden; // See https://github.com/twitter/bootstrap/issues/5319
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden
// See git pull https://github.com/dannykeane/bootstrap.git backface-visibility for examples
.backface-visibility(@visibility){
	-webkit-backface-visibility: @visibility;
	   -moz-backface-visibility: @visibility;
	        backface-visibility: @visibility;
}

// Background clipping
// Heads up: FF 3.6 and under need "padding" instead of "padding-box"
.background-clip(@clip) {
  -webkit-background-clip: @clip;
     -moz-background-clip: @clip;
          background-clip: @clip;
}

// Background sizing
.background-size(@size) {
  -webkit-background-size: @size;
     -moz-background-size: @size;
       -o-background-size: @size;
          background-size: @size;
}


// User select
// For selecting text on the page
.user-select(@select) {
  -webkit-user-select: @select;
     -moz-user-select: @select;
      -ms-user-select: @select;
       -o-user-select: @select;
          user-select: @select;
}

// CSS3 Content Columns
.content-columns(@columnCount, @columnGap: @gridGutterWidth) {
  -webkit-column-count: @columnCount;
     -moz-column-count: @columnCount;
          column-count: @columnCount;
  -webkit-column-gap: @columnGap;
     -moz-column-gap: @columnGap;
          column-gap: @columnGap;
}

// Optional hyphenation
.hyphens(@mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: @mode;
     -moz-hyphens: @mode;
      -ms-hyphens: @mode;
       -o-hyphens: @mode;
          hyphens: @mode;
}

.background(@startColor, @endColor) {
  background-color: mix(@startColor, @endColor, 60%);
  background-image: -moz-linear-gradient(top, @startColor, @endColor); // FF 3.6+
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, @startColor, @endColor); // Safari 5.1+, Chrome 10+
  background-image: -o-linear-gradient(top, @startColor, @endColor); // Opera 11.10
  background-image: linear-gradient(to bottom, @startColor, @endColor); // Standard, IE10
  background-repeat: repeat-x;
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@startColor),argb(@endColor))); // IE9 and down
}

// Opacity
.opacity(@opacity) {
  -webkit-opacity: @opacity;
   -khtml-opacity: @opacity;
     -moz-opacity: @opacity;
          opacity: @opacity;
          @opperc: @opacity * 100;
       -ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})";
           filter: ~"alpha(opacity=@{opperc})";
}

// Filter
.filter(@filter) {
  -webkit-filter: @filter;
     -moz-filter: @filter;
      -ms-filter: @filter;
       -o-filter: @filter;
          filter: @filter;
}

// IE7 Inline block fix
.inline-block() {
  display: inline-block;
  .lt-ie8 & {
    display: inline;
    zoom: 1;
  }
}

// Word and letter spacing
.spacing() {
  letter-spacing: -0.31em;
  *letter-spacing: normal;
  *word-spacing: -0.43em;
}
.spacing-normal() {
  letter-spacing: normal;
  word-spacing: normal;
}

// Clearfix
.clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
  .lt-ie8 & {
    zoom: 1;
  }
}
