//
// GLOBAL
//

// CONTENT
#content {
  background-color: @white;
  padding-top: 80px;
	position: relative;
	z-index: 5;
}

// SECTION META
.meta {
  ul {
    li {
      margin-bottom: 20px;
      line-height: 1.35em;
      strong {
        display: block;
        .btBold;
        margin-bottom: 3px;
        div {
          display: inline;
        }
      }
      span {
        margin-left: 0;
      }
    }
  }
}

// SECTION DOCUMENTS
.documents {
  h2 {
    margin-bottom: 0.5em;
  }
  ul {
    list-style: none;
    margin: 0 auto 1.5em;
    max-width: 680px;
    li {
      line-height: 1em;
    }
  }
}
a.document {
  border-top: 1px solid transparent;
  color: @color-secondary;
  display: block;
  line-height: 1.3em;
  margin: 0;
  min-height: 37px;
  padding: 15px 15px 15px 75px;
  position: relative;
  text-decoration: none;
  .transition(all .35s @ease);
  &:hover {
    span.name::after {
      width: 100%;
    }
  }
  i {
    color: @color-secondary;
    font-size: 2.8rem;
    left: 0;
    position: absolute;
    top: 18px;
  }
  span {
    display: block;
    font-size: 1.0555555556rem;
    line-height: 1.5789473684em;
    &.name {
      .inline-block();
      position: relative;
      text-decoration: none;
      &::before {
        background-color: @color-tertiary;
        bottom: -2px;
        content: '';
        display: block;
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: -1;
      }
      &::after {
        background-color: @color-tertiary;
        bottom: 0;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        width: 0;
        z-index: -1;
        .transition(width .35s @ease);
      }
    }
    &.meta {
      color: #cccccc;
      font-weight: normal;
    }
  }
}

@temporary: @contentDefaultWidth+180px;

// ANCHORS
.pageAnchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}

@media (min-width: @breakpointMobileSmall) {
  // SECTION META
  .meta {
    ul {
      li {
        margin-bottom: 10px;
        strong {
          display: inline;
        }
        span {
          margin-left: 7px;
        }
      }
    }
  }

  // SECTION DOCUMENTS
  .documents {
    padding: 0 15px;
  }
}