/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.lt-ie8 .clearfix {
  zoom: 1;
}
* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
html {
  font-size: 18px;
  height: 100% !important;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #22202b;
  color: #22202b;
  font-family: 'bt_regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  height: 100%;
  line-height: 1.77777778em;
}
body.logged-in {
  padding-top: 30px;
}
body.logged-in #header {
  top: 30px;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  color: #22202b;
  transition: color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
a:hover {
  color: #504c65;
}
a:focus {
  color: #22202b;
}
figure {
  margin: 0;
}
img {
  vertical-align: middle;
}
.img-circle {
  border-radius: 50%;
}
.element-invisible {
  display: none;
}
.alignLeft {
  text-align: right;
}
.alignRight {
  text-align: right;
}
.alignCenter {
  text-align: center;
}
*::selection {
  background: #bfdfff;
}
*::-moz-selection {
  background: #bfdfff;
}
#container {
  position: relative;
  z-index: 100;
}
#container.active {
  left: 0;
}
.lt-ie9 #container {
  min-width: 768px;
}
#container::after,
#container::before {
  background: #22202b;
  content: '';
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-transform: scaleX(1) translateZ(0);
  -ms-transform: scaleX(1);
  transform: scaleX(1) translateZ(0);
}
.no-js #container::after,
.no-js #container::before {
  display: none;
}
.front #container::before {
  display: none;
}
#container::after {
  left: auto;
  right: 0;
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scaleX(-1) translateZ(0);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1) translateZ(0);
}
#container.is-ready::before {
  -webkit-transition: 1s 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: 1s 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: scaleX(0) translateZ(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0) translateZ(0);
  visibility: hidden;
}
#container.is-leaving::before {
  -webkit-transform: scaleX(0) translateZ(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0) translateZ(0);
}
#container.is-leaving::after {
  -webkit-transition: 1s 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: 1s 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  visibility: visible;
  -webkit-transform: scaleX(1) translateZ(0);
  -ms-transform: scaleX(1);
  transform: scaleX(1) translateZ(0);
}
.widthWrapper {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  width: auto;
  padding: 0 15px;
}
.widthWrapper:before,
.widthWrapper:after {
  content: "";
  display: table;
}
.widthWrapper:after {
  clear: both;
}
.lt-ie8 .widthWrapper {
  zoom: 1;
}
.articleHeader p,
.articleContent p,
.articleHeader h1,
.articleContent h1,
.articleHeader h2,
.articleContent h2,
.articleHeader h3,
.articleContent h3,
.articleHeader h4,
.articleContent h4,
.articleHeader h5,
.articleContent h5,
.articleHeader h6,
.articleContent h6,
.articleHeader ul,
.articleContent ul,
.articleHeader ol,
.articleContent ol,
.articleHeader blockquote,
.articleContent blockquote,
.articleHeader .normalWidth,
.articleContent .normalWidth {
  margin: 0 auto;
  max-width: 650px;
}
section .mediumWidth,
section.mediumWidth,
section.teamSingle {
  margin: 0 auto;
  max-width: 875px;
}
section.teamOverview {
  max-width: none;
}
section .fullWidth,
section.fullWidth {
  max-width: 100%;
}
section.share,
section.meta,
section.workAtBetawerk {
  margin: 0 auto;
  max-width: 650px;
  padding: 0 15px;
}
section.workAtBetawerk {
  margin: 10px auto;
  padding: 0;
}
.caseSlider .rsGCaption .title,
.caseSlider .rsGCaption .subtitle {
  padding: 0 15px;
}
.colsWrapper {
  margin-bottom: 2em;
  overflow: hidden;
}
.colsWrapper.home {
  padding-top: 60px;
  padding-bottom: 60px;
}
.colsOneThird {
  float: none;
  margin: 0 0 20px 0;
  position: relative;
  width: 100%;
}
.colsOneThird strong {
  font-family: 'bt_bold';
  font-weight: normal;
}
.colsOneThird p,
.colsOneThird ul {
  margin-bottom: 0.6em;
}
.colsOneThird h2 {
  font-family: 'bt_bold';
  font-weight: normal;
  margin-bottom: 10px;
}
.colsOneThird .image img {
  height: auto;
  max-width: 100%;
}
.colsOneThird .image.drupalLogo {
  max-width: 130px;
}
@media (min-width: 400px) {
  .widthWrapper {
    padding: 0 30px;
  }
  section.share,
  section.meta,
  section.workAtBetawerk {
    margin: 0 auto;
    padding: 0 30px;
  }
}
@media (min-width: 700px) {
  .colsOneThird {
    margin: 0 3% 0 2%;
    float: left;
    width: 28.3%;
  }
}
#content {
  background-color: #ffffff;
  padding-top: 80px;
  position: relative;
  z-index: 5;
}
.meta ul li {
  margin-bottom: 20px;
  line-height: 1.35em;
}
.meta ul li strong {
  display: block;
  font-family: 'bt_bold';
  font-weight: normal;
  margin-bottom: 3px;
}
.meta ul li strong div {
  display: inline;
}
.meta ul li span {
  margin-left: 0;
}
.documents h2 {
  margin-bottom: 0.5em;
}
.documents ul {
  list-style: none;
  margin: 0 auto 1.5em;
  max-width: 680px;
}
.documents ul li {
  line-height: 1em;
}
a.document {
  border-top: 1px solid transparent;
  color: #22202b;
  display: block;
  line-height: 1.3em;
  margin: 0;
  min-height: 37px;
  padding: 15px 15px 15px 75px;
  position: relative;
  text-decoration: none;
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
a.document:hover span.name::after {
  width: 100%;
}
a.document i {
  color: #22202b;
  font-size: 2.8rem;
  left: 0;
  position: absolute;
  top: 18px;
}
a.document span {
  display: block;
  font-size: 1.05555556rem;
  line-height: 1.57894737em;
}
a.document span.name {
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.lt-ie8 a.document span.name {
  display: inline;
  zoom: 1;
}
a.document span.name::before {
  background-color: #e6fa37;
  bottom: -2px;
  content: '';
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}
a.document span.name::after {
  background-color: #e6fa37;
  bottom: 0;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  width: 0;
  z-index: -1;
  -webkit-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
a.document span.meta {
  color: #cccccc;
  font-weight: normal;
}
.pageAnchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}
@media (min-width: 400px) {
  .meta ul li {
    margin-bottom: 10px;
  }
  .meta ul li strong {
    display: inline;
  }
  .meta ul li span {
    margin-left: 7px;
  }
  .documents {
    padding: 0 15px;
  }
}
section.cases {
  background-color: #ffffff;
}
.node-type-case #content {
  padding-bottom: 50px;
  padding-top: 0;
}
#caseMainImage {
  background-color: #22202b;
  display: block;
  height: 67vh;
  position: relative;
  width: 100%;
  z-index: -1;
}
#caseMainImage:after {
  background: url('../images/bg-case.png') no-repeat 0 0 transparent;
  background-size: cover;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-animation: fadeIn 1.5s ease-in-out;
  -moz-animation: fadeIn 1.5s ease-in-out;
  animation: fadeIn 1.5s ease-in-out;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  animation-delay: 2s;
}
#caseMainImage .caseMainImage-inner {
  display: block;
  height: 70vh;
  position: relative;
  width: 100%;
}
#caseMainImage #waypoint-trigger {
  top: 80px;
  position: absolute;
}
#caseMainImage .widthWrapper {
  max-width: 1200px;
  z-index: 2;
}
.touchevents #caseMainImage .widthWrapper {
  position: static;
}
#caseMainImage .quote {
  margin: 0 auto;
}
#caseMainImage blockquote {
  color: #ffffff;
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 1.6rem;
  line-height: 1.42857143em;
  max-width: 250px;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  top: 26vh;
  position: fixed;
  -webkit-animation: fadeInAndMove 2.5s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-animation: fadeInAndMove 2.5s cubic-bezier(0.23, 1, 0.32, 1);
  animation: fadeInAndMove 2.5s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.touchevents #caseMainImage blockquote {
  position: absolute;
  z-index: 99;
}
#caseMainImage img {
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-animation: fadeIn 1.5s ease-in-out;
  -moz-animation: fadeIn 1.5s ease-in-out;
  animation: fadeIn 1.5s ease-in-out;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
@media (min-width: 500px) {
  #caseMainImage {
    height: 79vh;
  }
  #caseMainImage .caseMainImage-inner {
    height: 82vh;
  }
}
@media (min-width: 600px) {
  #caseMainImage blockquote {
    font-size: 1.94444444rem;
    max-width: 400px;
    top: 31%;
  }
}
.hiLite {
  padding: 2.5em 1em 1em;
  margin: 3em auto 3em;
  background-color: #e6fa37;
  max-width: 800px;
  box-shadow: 0px 30px 30px -35px rgba(0, 0, 0, 0.4);
}
.features.hasBorderTop {
  border-top: 2px solid rgba(0, 0, 0, 0.07);
  margin-top: 1.3em !important;
  padding-top: 1em;
}
ul.features li {
  line-height: 1.2em !important;
  list-style: none;
  margin-left: 0 !important;
  padding: 0.6em 0 0.6em 2em !important;
  position: relative;
}
ul.features li::before {
  background: transparent url('../images/svg/betawerk_check.svg') center center no-repeat;
  content: '';
  display: block;
  height: 15px;
  left: 0;
  position: absolute;
  top: 13px;
  width: 19px;
}
.services .colsOneThird {
  border-top: 2px solid rgba(0, 0, 0, 0.07);
  margin-top: 1em !important;
  padding-top: 2.2em;
}
.services .colsOneThird:last-child ul {
  margin-bottom: 0;
}
.services .colsOneThird .image {
  margin-bottom: 10px;
  width: 100%;
}
.services .colsOneThird .text {
  padding-left: 0;
}
.services .colsOneThird .text p {
  margin: 0 0 1.2em 0;
}
.services .colsOneThird h2,
.services .colsOneThird p,
.services .colsOneThird ul {
  padding: 0;
}
.services .colsOneThird h2 {
  font-size: 1.22222222rem;
  margin: 1em 0;
}
.teamSingle .teamMember {
  margin: 0 0 20px 0;
  overflow: hidden;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 20px;
}
.teamSingle .teamMember .image {
  float: none;
  width: 100%;
  margin-right: 3%;
}
.teamSingle .teamMember .image img {
  display: block;
  height: auto;
  max-width: 100%;
}
.teamSingle .teamMember .text {
  font-size: 0.83333333rem;
  line-height: 1.33333333em;
  overflow: hidden;
  padding: 15px 0 0;
  word-wrap: break-word;
}
.teamSingle .teamMember .text h1 {
  font-size: 1.05555556rem;
  font-family: 'bt_bold';
  font-weight: normal;
}
.teamSingle .teamMember .text p {
  margin-bottom: 1em;
}
.teamSingle .teamMember .text .socialLinks {
  font-size: 90%;
}
ul.team {
  overflow: hidden;
  letter-spacing: -0.31em;
  *letter-spacing: normal;
  *word-spacing: -0.43em;
}
ul.team li {
  display: inline-block;
  list-style: none;
  margin: 0 0 10px;
  padding: 0;
  vertical-align: top;
  width: 100%;
  letter-spacing: normal;
  word-spacing: normal;
}
.lt-ie8 ul.team li {
  display: inline;
  zoom: 1;
}
ul.team li a {
  font-size: 0.83333333rem;
  line-height: 1.26666667em;
  color: #22202b;
  display: block;
  overflow: hidden;
  text-decoration: none;
  -webkit-transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
ul.team li a:hover {
  background-color: #eee;
}
ul.team li .image {
  float: left;
  width: 30%;
  margin-right: 3%;
}
ul.team li img {
  display: block;
  height: auto;
  max-width: 100%;
}
ul.team li .info {
  padding: 1em 0 0;
}
ul.team li h3 {
  font-weight: bold;
  line-height: 1.5em;
  padding-top: 2px;
}
ul.team li p {
  line-height: 1.5em;
}
.workAtBetawerk {
  text-align: center;
}
.workAtBetawerk .inner {
  padding: 20px;
}
.workAtBetawerk p {
  line-height: 1.16666667em;
  margin-bottom: 1.2em;
}
.workAtBetawerk p:last-child {
  margin-bottom: 0;
}
.related {
  display: none;
}
ul.has-checks {
  background-color: #f6f6f4;
  list-style: none;
  padding: 27px 20px 28px;
}
ul.has-checks li {
  background-image: url('../images/icon-check.png');
  background-position: 0 10px;
  background-repeat: no-repeat;
  margin-left: 0 !important;
  padding-left: 34px !important;
  list-style: none;
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  ul.has-checks li {
    background-image: url('../images/icon-check@2x.png');
    background-size: 19px 14px;
  }
}
@media (min-width: 400px) {
  .teamSingle .teamMember {
    margin: 0 3% 20px 2%;
  }
  .teamSingle .teamMember .image {
    float: left;
    width: 48.5%;
  }
  .teamSingle .teamMember .text {
    padding: 5px 0 0;
  }
  ul.team li {
    margin: 0 2% 20px;
    width: 45%;
  }
  ul.team li .image {
    float: none;
    margin-right: 0;
    width: 100%;
  }
  ul.has-checks {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 700px) {
  ul.team li {
    margin: 0 3% 2em 3%;
    width: 27%;
  }
}
@font-face {
  font-family: 'bt_black';
  src: url('../fonts/brandon_text/BrandonText-Black.woff2') format('woff2'), url('../fonts/brandon_text/BrandonText-Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'bt_bold';
  src: url('../fonts/brandon_text/BrandonText-Bold.woff2') format('woff2'), url('../fonts/brandon_text/BrandonText-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'bt_bold_italic';
  src: url('../fonts/brandon_text/BrandonText-Bold.woff2') format('woff2'), url('../fonts/brandon_text/BrandonText-Bold.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'bt_regular';
  src: url('../fonts/brandon_text/BrandonText-Regular.woff2') format('woff2'), url('../fonts/brandon_text/BrandonText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'bt_regular_italic';
  src: url('../fonts/brandon_text/BrandonText-Regular.woff2') format('woff2'), url('../fonts/brandon_text/BrandonText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'bt_light';
  src: url('../fonts/brandon_text/BrandonText-Light.woff2') format('woff2'), url('../fonts/brandon_text/BrandonText-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
.btLight {
  font-family: 'bt_light';
  font-weight: normal;
}
.btRegular {
  font-family: 'bt_regular';
  font-weight: normal;
}
.btItalic {
  font-family: 'bt_regular_italic';
  font-weight: normal;
  font-style: italic;
}
.btBold {
  font-family: 'bt_bold';
  font-weight: normal;
}
.btBoldItalic {
  font-family: 'bt_bold_italic';
  font-weight: normal;
  font-style: italic;
}
.btBlack {
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
a {
  color: #22202b;
}
a:hover {
  color: #504c65;
}
.articleHeader h1 {
  color: #22202b;
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 1.88888889rem;
  line-height: 1.1em;
}
.articleHeader h2 {
  color: #22202b;
  font-size: 1.11111111rem;
  line-height: 1.5em;
  margin-bottom: 0;
  text-transform: uppercase;
}
strong {
  font-family: 'bt_bold';
  font-weight: normal;
}
strong em,
em strong {
  font-family: 'bt_bold_italic';
  font-weight: normal;
  font-style: italic;
}
.underline a {
  color: #22202b;
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.lt-ie8 .underline a {
  display: inline;
  zoom: 1;
}
.underline a.image-link {
  display: inline;
}
.underline a:hover {
  color: #22202b;
}
.underline a:hover::after {
  width: 100%;
}
.underline a::before {
  background-color: #e6fa37;
  bottom: 0;
  content: '';
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.underline a::after {
  background-color: #e6fa37;
  bottom: 0;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  width: 0;
  z-index: -1;
  -webkit-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.articleContent {
  letter-spacing: 0.015em;
}
.articleContent p,
.articleContent ul,
.articleContent ol {
  font-size: 18px;
  letter-spacing: 0.02em !important;
  line-height: 1.77777778em;
  margin-bottom: 1.77777778em;
}
.articleContent p a {
  color: #22202b;
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.lt-ie8 .articleContent p a {
  display: inline;
  zoom: 1;
}
.articleContent p a.image-link {
  display: inline;
}
.articleContent p a:hover {
  color: #22202b;
}
.articleContent p a:hover::after {
  width: 100%;
}
.articleContent p a::before {
  background-color: #e6fa37;
  bottom: 0;
  content: '';
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.articleContent p a::after {
  background-color: #e6fa37;
  bottom: 0;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  width: 0;
  z-index: -1;
  -webkit-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.articleContent em {
  font-family: 'bt_regular_italic';
  font-weight: normal;
  font-style: italic;
}
.articleContent a {
  color: inherit;
}
.articleContent small {
  font-size: 85%;
  color: #666;
}
.articleContent small a {
  color: inherit;
}
.articleContent ol {
  list-style: decimal outside none;
}
.articleContent ol li {
  margin-left: 1.2em;
  padding-left: 0.25em;
}
.articleContent ul {
  list-style: disc outside none;
}
.articleContent ul li {
  margin-left: 1.2em;
  padding-left: 0.25em;
}
.articleContent h1,
.articleContent h2,
.articleContent h3,
.articleContent h4,
.articleContent h5,
.articleContent h6 {
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 1.11111111rem;
  margin-bottom: 0.2em;
}
@media (max-width: 767px) {
  .articleContent h1,
  .articleContent h2 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
}
.articleContent h1 {
  font-size: 2rem;
}
.articleContent h2 {
  font-size: 1.66666667rem;
}
.articleContent h3 {
  text-transform: none !important;
  font-size: 1.6em !important;
  line-height: 1.1em !important;
  margin-bottom: 0.7em !important;
}
.articleContent .intro {
  font-size: 1.15rem;
  font-family: 'bt_bold';
  font-weight: normal;
  text-transform: none;
}
.articleContent blockquote {
  margin-top: 3em;
  margin-bottom: 2em;
}
.articleContent blockquote p {
  position: relative;
}
.articleContent blockquote .quotation-mark {
  color: #e6fa37;
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 190px;
  font-weight: bold;
  vertical-align: middle;
  width: 40px;
}
.articleContent blockquote .quotation-mark::before {
  content: "\2018";
  display: block;
  height: 0;
  -webkit-transform: translate(-18px, 5px);
  -moz-transform: translate(-18px, 5px);
  -ms-transform: translate(-18px, 5px);
  -o-transform: translate(-18px, 5px);
  transform: translate(-18px, 5px);
}
.firefox .articleContent blockquote .quotation-mark::before {
  -webkit-transform: translate(-18px, 18px);
  -moz-transform: translate(-18px, 18px);
  -ms-transform: translate(-18px, 18px);
  -o-transform: translate(-18px, 18px);
  transform: translate(-18px, 18px);
}
.articleContent blockquote .quote-text {
  background-color: #e6fa37;
  box-shadow: 10px 0 0 #e6fa37, -10px 0 0 #e6fa37;
  color: #22202b;
  display: inline;
  font-family: 'bt_bold';
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.5em;
  padding: 5px 0;
  margin-bottom: 0;
  -webkit-box-decoration-break: clone;
  -ms-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
}
.articleContent blockquote .quote-text-small {
  background-color: #e6fa37;
  box-shadow: 10px 0 0 #e6fa37, -10px 0 0 #e6fa37;
  color: #22202b;
  display: inline;
  font-family: 'bt_bold';
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 1.5em;
  padding: 5px 0;
  margin-bottom: 0;
  -webkit-box-decoration-break: clone;
  -ms-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
}
.articleContent blockquote .quote-source {
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  font-family: 'bt_regular';
  font-weight: normal;
  font-size: 85%;
  line-height: 1.5em;
  margin-bottom: 0;
  margin-top: 20px;
  padding: 5px 0;
}
.articleContent blockquote .quote-source::before {
  content: '\2013';
  margin-right: 5px;
}
.related .inner > h3 {
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 1.11111111rem;
  margin: 40px 0;
}
.contentBlocks .contentBlock h2 {
  font-size: 1.33333333rem;
  font-weight: bold;
}
.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
@media (min-width: 400px) {
  .articleHeader h1 {
    font-size: 2.44444444rem;
  }
  .articleContent blockquote .quotation-mark {
    width: 30px;
  }
  .articleContent blockquote .quotation-mark::before {
    -webkit-transform: translate(-33px, 5px);
    -moz-transform: translate(-33px, 5px);
    -ms-transform: translate(-33px, 5px);
    -o-transform: translate(-33px, 5px);
    transform: translate(-33px, 5px);
  }
  .firefox .articleContent blockquote .quotation-mark::before {
    -webkit-transform: translate(-33px, 18px);
    -moz-transform: translate(-33px, 18px);
    -ms-transform: translate(-33px, 18px);
    -o-transform: translate(-33px, 18px);
    transform: translate(-33px, 18px);
  }
  .articleContent blockquote p {
    font-size: 1.88888889rem;
  }
}
@media (min-width: 768px) {
  .articleContent blockquote .quotation-mark::before {
    -webkit-transform: translate(-60px, 5px);
    -moz-transform: translate(-60px, 5px);
    -ms-transform: translate(-60px, 5px);
    -o-transform: translate(-60px, 5px);
    transform: translate(-60px, 5px);
  }
  .firefox .articleContent blockquote .quotation-mark::before {
    -webkit-transform: translate(-60px, 18px);
    -moz-transform: translate(-60px, 18px);
    -ms-transform: translate(-60px, 18px);
    -o-transform: translate(-60px, 18px);
    transform: translate(-60px, 18px);
  }
  .articleContent blockquote .quote-text,
  .articleContent blockquote .quote-text-small {
    left: -30px;
  }
}
/* --- TOKEN IMAGES responsive--- */
.tokenImage,
.tokenSlider {
  display: block;
  float: left;
  margin: 6px 3% 25px 0;
  width: 48.5%;
  /* default is gelijk aan medium */
}
.tokenImage img,
.tokenSlider img {
  display: block;
  height: auto;
  margin: 0 auto;
  width: 100%;
}
.tokenImage .caption,
.tokenSlider .caption {
  color: #999;
  display: block;
  font-size: 0.75rem;
  line-height: 1.2em;
  padding: 6px 0 0 2px;
  margin: 0 auto;
  text-align: left;
}
.tokenImage .caption a,
.tokenSlider .caption a,
.tokenImage .caption a:hover,
.tokenSlider .caption a:hover {
  color: inherit;
  text-decoration: underline;
}
.tokenImage.mini,
.tokenSlider.mini {
  width: 23.5%;
}
.tokenImage.small,
.tokenSlider.small {
  width: 31.8333%;
}
.tokenImage.medium,
.tokenSlider.medium {
  width: 48.5%;
}
.tokenImage.large,
.tokenSlider.large {
  width: 65.1666%;
}
.tokenImage.full,
.tokenSlider.full {
  width: 100%;
}
.tokenImage.right,
.tokenSlider.right {
  float: right;
  margin: 6px 0 25px 3%;
}
.tokenImage.full,
.tokenSlider.full,
.tokenImage.center,
.tokenSlider.center {
  float: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
  margin-bottom: 3em;
}
/* mobile viewport */
@media (max-width: 550px) {
  .tokenImage.mini,
  .tokenSlider.mini {
    width: 35%;
  }
  .tokenImage.small,
  .tokenSlider.small {
    width: 45%;
  }
  .tokenImage.medium,
  .tokenSlider.medium {
    width: 55%;
  }
}
/* mobileSmall viewport */
@media (max-width: 400px) {
  .tokenImage.mini,
  .tokenSlider.mini,
  .tokenImage.small,
  .tokenSlider.small,
  .tokenImage.medium,
  .tokenSlider.medium,
  .tokenImage.large,
  .tokenSlider.large,
  .tokenImage.full,
  .tokenSlider.full {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .tokenImage.mini,
  .tokenSlider.mini,
  .tokenImage.small,
  .tokenSlider.small {
    width: 50%;
  }
  .tokenImage.medium,
  .tokenSlider.medium {
    width: 80%;
  }
  .tokenImage.large,
  .tokenSlider.large {
    width: 100%;
  }
  .tokenSlider.small {
    width: 65%;
  }
}
.button {
  background-color: #22202b;
  color: #ffffff !important;
  display: inline-block;
  font-size: 0.83333333rem;
  font-family: 'bt_bold';
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 1em;
  overflow: hidden;
  padding: 18px 22px 16px;
  text-decoration: none !important;
  text-transform: uppercase;
  border-radius: 4px;
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.lt-ie8 .button {
  display: inline;
  zoom: 1;
}
.button:hover {
  background-color: #e3f91e;
  color: #22202b !important;
  cursor: pointer;
  text-decoration: none !important;
}
.button.full {
  display: block;
  text-align: center;
}
.button.small {
  font-size: 0.72222222rem;
  padding: 13px 16px;
}
.button.large {
  font-size: 115%;
}
.button.edit {
  background: url('../images/drupal7-buttons.png') repeat-x 0 0 #e9e9e9;
  border: 1px solid;
  border-color: #e4e4e4 #d2d2d2 #b4b4b4;
  color: #5a5a5a !important;
  cursor: pointer;
  font-family: "Lucida Grande", Verdana, sans-serif;
  font-size: 0.72222222rem;
  font-weight: normal;
  margin-right: 0.3em;
  padding: 4px 11px;
  text-align: center;
  text-decoration: none !important;
  text-transform: none;
  border-radius: 20px;
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.button.edit:hover {
  background-position: left -40px;
  border-color: #b4b4b4;
  color: #222222 !important;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
  -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
}
.button.secondary {
  background-color: #e6fa37;
  color: #22202b !important;
}
.button.tertiary {
  background-color: #e6fa37;
  color: #22202b !important;
  padding: 19px 42px 17px;
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
.button.white {
  background-color: #ffffff;
  color: #22202b !important;
}
.button.white:hover {
  background-color: #e6fa37;
  color: #22202b !important;
}
.share {
  background: #22202b;
  max-width: none !important;
  margin-top: 3em !important;
}
.share .shareLinks {
  list-style: none;
  margin: 10px 0 0;
  padding: 30px 0 10px;
  text-align: center;
  letter-spacing: -0.31em;
  *letter-spacing: normal;
  *word-spacing: -0.43em;
}
.share .shareLinks span.txt {
  color: #e6fa37;
  display: block;
  font-size: 0.77777778rem;
  font-weight: normal;
  line-height: 2.14285714em;
  padding: 0 10px 0 0;
}
.share .shareLinks a span {
  background-image: url("../images/sprite-social-icons.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-color: transparent;
  display: block;
  height: 30px;
  width: 32px;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .share .shareLinks a span {
    background-image: url("../images/sprite-social-icons@2x.png");
    background-size: 240px 30px;
  }
}
.lt-ie9 .share .shareLinks a span {
  background-color: #ffffff;
}
.share .shareLinks a:hover span {
  -webkit-opacity: 0.65;
  -khtml-opacity: 0.65;
  -moz-opacity: 0.65;
  opacity: 0.65;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=65);
  filter: alpha(opacity=65);
}
.lt-ie8 .share .shareLinks a:hover span {
  cursor: pointer;
}
.share .shareLinks li {
  display: inline-block;
  vertical-align: top;
  letter-spacing: normal;
  word-spacing: normal;
}
.lt-ie8 .share .shareLinks li {
  display: inline;
  zoom: 1;
}
.share .shareLinks li.linkedin span {
  background-position: left center;
}
.share .shareLinks li.facebook span {
  background-position: -30px center;
}
.share .shareLinks li.twitter span {
  background-position: -60px center;
}
.share .shareLinks li.email span {
  background-position: -90px center;
}
.socialLinks {
  list-style: none;
}
.socialLinks a {
  display: block;
  padding: 4px 0 4px 26px;
  position: relative;
  text-decoration: none;
}
.socialLinks li {
  clear: both;
  display: block;
  float: none;
  position: relative;
}
.socialLinks li span.icon {
  background-image: url("../images/sprite-social-icons.png");
  background-repeat: no-repeat;
  background-position: -120px center;
  background-color: transparent;
  display: block;
  float: left;
  height: 30px;
  left: -6px;
  position: absolute;
  top: -2px;
  width: 30px;
  -webkit-opacity: 0.3;
  -khtml-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=30);
  filter: alpha(opacity=30);
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .socialLinks li span.icon {
    background-image: url("../images/sprite-social-icons@2x.png");
    background-size: 240px 30px;
  }
}
.socialLinks li.linkedin span.icon {
  background-position: left center;
}
.socialLinks li.facebook span.icon {
  background-position: -30px center;
}
.socialLinks li.twitter span.icon {
  background-position: -60px center;
}
.socialLinks li.email span.icon {
  background-position: -90px center;
}
.socialLinks li:hover span.icon {
  -webkit-opacity: 0.75;
  -khtml-opacity: 0.75;
  -moz-opacity: 0.75;
  opacity: 0.75;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=75);
  filter: alpha(opacity=75);
}
.lt-ie8 .socialLinks li:hover span.icon {
  cursor: pointer;
}
@media (min-width: 700px) {
  .share .shareLinks {
    padding: 40px 0 20px;
  }
}
iframe {
  max-width: 100%;
}
.slider-item {
  display: none;
}
.rsSlide .slider-item {
  display: block;
}
.rsSlide .slider-item .rsCookiePlaceholder {
  background-color: #151515;
  position: relative;
}
.rsSlide .slider-item .rsCookiePlaceholder p {
  font-size: 15px;
  padding: 1rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0;
  max-width: none;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.rsSlide .slider-item .rsCookiePlaceholder p a {
  color: #ffffff;
}
.rsSlide .slider-item .rsCookiePlaceholder p a:hover {
  color: #000000;
}
.tokenSlider {
  position: relative;
}
.tokenSlider .rsDefault {
  background: #ffffff;
}
.tokenSlider .rsDefault .rsOverflow {
  background: #ffffff;
}
.tokenSlider .rsDefault .rsOverflow .rsSlide {
  background: #ffffff;
}
.tokenSlider .rsDefault .rsBullets {
  background: none;
  float: left;
  left: auto;
  position: relative;
  right: 0;
  width: auto;
}
.tokenSlider .rsDefault .rsBullets .rsBullet {
  padding: 14px 9px 10px;
}
.tokenSlider .rsDefault .rsBullets .rsBullet span {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.tokenSlider .rsDefault .rsBullets .rsBullet.rsNavSelected span,
.tokenSlider .rsDefault .rsBullets .rsBullet.rsNavSelected:hover span {
  background-color: #22202b;
}
.tokenSlider .rsDefault .rsBullets .rsBullet:hover {
  cursor: pointer;
}
.tokenSlider .rsDefault .rsBullets .rsBullet:hover span {
  background-color: #22202b;
}
.tokenSlider .rsDefault .rsGCaption {
  background: none;
  bottom: 0;
  clear: both;
  color: #666666;
  float: none;
  font-size: 13.5px;
  height: auto;
  left: 0;
  line-height: 1.8em;
  overflow: hidden;
  padding: 5px 8px 0 8px;
  position: relative;
  font-size: 13px;
  border-radius: 0;
  -webkit-opacity: 0.65;
  -khtml-opacity: 0.65;
  -moz-opacity: 0.65;
  opacity: 0.65;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=65);
  filter: alpha(opacity=65);
}
.tokenSlider.deviceSlider {
  box-sizing: content-box;
  background: #000000;
  overflow: hidden;
  z-index: 40;
  border-radius: 4px;
}
.tokenSlider.deviceSlider .sliderWrapper {
  width: 100%;
}
.tokenSlider.deviceSlider .sliderWrapper .rsDefault {
  background: #000000;
  padding: 3% 0 0 3%;
  position: relative;
  width: 97%;
  border-radius: 4px;
}
.tokenSlider.deviceSlider .sliderWrapper .rsDefault::after {
  background: url('../royalslider/skins/default/device-overlay.png') right top no-repeat transparent;
  background-size: 50% 100%;
  content: '';
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 2px;
  width: 100%;
  z-index: 50;
  -webkit-opacity: 0.5;
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  filter: alpha(opacity=50);
}
.tokenSlider.deviceSlider .sliderWrapper .rsDefault::after .lt-ie9 {
  display: none;
}
.tokenSlider.deviceSlider .sliderWrapper .rsDefault .rsOverflow {
  background: #000000;
}
.tokenSlider.deviceSlider .sliderWrapper .rsDefault .rsContainer {
  background: #000000;
}
.tokenSlider.deviceSlider .sliderWrapper .rsDefault div.rsGCaption {
  color: #cccccc;
  padding: 4px 0 20px;
}
.tokenSlider.deviceSlider .sliderWrapper .rsDefault div.rsBullets {
  margin-top: 4px;
}
.tokenSlider.deviceSlider .sliderWrapper .rsDefault div.rsBullets .rsBullet span {
  background-color: #666666;
}
.tokenSlider.deviceSlider .sliderWrapper .rsDefault div.rsBullets .rsBullet:hover span {
  background-color: #999999;
}
.tokenSlider.deviceSlider .sliderWrapper .rsDefault div.rsBullets .rsBullet.rsNavSelected span {
  background-color: #cccccc;
}
.tokenSlider.deviceSlider.noAfter .rsDefault::after {
  background-size: 0 0;
  z-index: -1;
}
#headerNav,
#secondNav {
  display: none;
  overflow: hidden;
}
#headerNav ul li,
#secondNav ul li {
  display: inline-block;
  list-style: none;
  list-style-image: none;
  list-style-type: none;
  margin: 0 11px;
  padding: 0;
}
.lt-ie8 #headerNav ul li,
.lt-ie8 #secondNav ul li {
  display: inline;
  zoom: 1;
}
#headerNav ul li a,
#secondNav ul li a {
  color: #ffffff;
  display: block;
  font-size: 0.75rem;
  font-family: 'bt_bold';
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 5.92592593em;
  padding: 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}
#headerNav ul li a:after,
#secondNav ul li a:after {
  background-color: #e6fa37;
  content: '';
  display: block;
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-transition: height 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: height 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: height 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: height 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
#headerNav ul li a:hover:after,
#secondNav ul li a:hover:after,
#headerNav ul li a.active:after,
#secondNav ul li a.active:after,
#headerNav ul li a.active-trail:after,
#secondNav ul li a.active-trail:after {
  height: 6px;
}
.node-type-case #headerNav ul li a,
.node-type-case #secondNav ul li a {
  color: #ffffff;
  -webkit-transition: color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.node-type-case.header-bg #headerNav ul li a,
.node-type-case.header-bg #secondNav ul li a {
  color: #ffffff;
}
#headerNav {
  margin-left: 0;
}
#headerNav ul {
  text-align: center;
}
#secondNav {
  position: absolute;
  right: 20px;
  top: 0;
}
#secondNav ul li a:after {
  display: none;
}
.mobileNavEffect {
  background-color: #e6fa37;
  content: '';
  display: block;
  height: 0;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 7;
}
#mobileNav {
  background-color: #22202b;
  display: block;
  height: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8;
}
#mobileNav ul.language-switcher-locale-url li.active {
  display: none;
}
#mobileNav ul li {
  list-style: none;
  list-style-image: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#mobileNav ul li a {
  color: #e6fa37;
  display: block;
  font-size: 0.83333333rem;
  font-family: 'bt_bold';
  font-weight: normal;
  line-height: 1em;
  letter-spacing: 0.1em;
  padding: 19px 0 16px 20px;
  text-decoration: none;
  text-transform: uppercase;
}
#mobileNav ul li a:hover {
  color: #ffffff;
  text-decoration: none;
}
#mobileNav ul li i {
  color: #ffffff;
  display: inline-block;
  font-size: 32px;
  margin-right: 10px;
  vertical-align: sub;
}
.lt-ie8 #mobileNav ul li i {
  display: inline;
  zoom: 1;
}
#mobileNav .menu {
  padding-top: 80px;
}
#mobileNav .menu li:first-child {
  margin-top: -10px;
}
.mobileControl {
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
.mobileControl a {
  color: #ffffff;
  display: block;
  font-size: 1.11111111rem;
  height: 100%;
  width: 60px;
}
.mobileControl a i {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.mobileControl a i.icon-close {
  display: none;
}
.mobileControl a i.icon-menu {
  display: block;
}
.mobilenav-is-shown .mobileControl a {
  color: #ffffff;
}
.mobilenav-is-shown .mobileControl a i.icon-close {
  display: block;
}
.mobilenav-is-shown .mobileControl a i.icon-menu {
  display: none;
}
.node-type-case .mobileControl a {
  color: #ffffff;
}
.node-type-case.header-bg .mobileControl a {
  color: #e6fa37;
}
.node-type-case.header-bg.mobilenav-is-shown .mobileControl a {
  color: #ffffff;
}
.caseNavigation {
  bottom: 0;
  height: 50px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 5;
}
.caseNavigation .nav-inner {
  margin: 0 auto;
  max-width: 1296px;
  position: relative;
}
.caseNavigation .nav-inner:after {
  background-color: #ffffff;
  content: '';
  display: none;
  height: 50px;
  left: 10.64814815%;
  position: absolute;
  top: 0;
  width: 78.7037037%;
}
.caseNavigation .arrow {
  height: 50px;
  left: 7px;
  position: absolute;
  width: 30px;
  z-index: 100;
}
.caseNavigation .arrow a {
  display: block;
  height: 100%;
  text-align: center;
  text-decoration: none;
  position: relative;
}
.caseNavigation .arrow a:hover i {
  -webkit-transform: translate(-7px, -50%);
  -moz-transform: translate(-7px, -50%);
  -ms-transform: translate(-7px, -50%);
  -o-transform: translate(-7px, -50%);
  transform: translate(-7px, -50%);
}
.caseNavigation .arrow a span {
  color: #ffffff;
  display: none;
  font-size: 0.77777778rem;
  padding: 0 5px;
  font-family: 'bt_bold';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
.caseNavigation .arrow a i {
  color: #ffffff;
  display: inline-block;
  font-size: 1.1rem;
  position: relative;
  top: 50%;
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.lt-ie8 .caseNavigation .arrow a i {
  display: inline;
  zoom: 1;
}
.caseNavigation .arrow.next {
  left: auto;
  right: 7px;
}
.caseNavigation .arrow.next:hover i {
  -webkit-transform: translate(7px, -50%);
  -moz-transform: translate(7px, -50%);
  -ms-transform: translate(7px, -50%);
  -o-transform: translate(7px, -50%);
  transform: translate(7px, -50%);
}
.caseNavigation .arrow.next span {
  padding-left: 10px;
}
.caseNavigation.bottom {
  margin-bottom: 30px;
  position: relative;
}
.caseNavigation.bottom:hover .arrow {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
.caseNavigation.bottom .arrow {
  -webkit-opacity: 0.5;
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  filter: alpha(opacity=50);
  -webkit-transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.caseNavigation.bottom .arrow span,
.caseNavigation.bottom .arrow i {
  color: #000000;
}
.contentSingleNavigation {
  margin: 0 auto;
  text-align: center;
  position: relative;
}
.contentSingleNavigation ul {
  display: none;
  background: #edeeea;
  margin: 1em auto 3em;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
.contentSingleNavigation ul li,
.contentSingleNavigation ul .leaf {
  float: left;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 25%;
}
.contentSingleNavigation ul li a,
.contentSingleNavigation ul .leaf a {
  color: #22202b;
  display: block;
  font-size: 0.77777778rem;
  font-family: 'bt_bold';
  font-weight: normal;
  letter-spacing: 0.05em;
  padding: 30px 0 28px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: background 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: background 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: background 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: background 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.contentSingleNavigation ul li a:hover,
.contentSingleNavigation ul .leaf a:hover {
  background: #e2e4de;
}
.contentSingleNavigation ul li a.active,
.contentSingleNavigation ul .leaf a.active,
.contentSingleNavigation ul li a.active-trail,
.contentSingleNavigation ul .leaf a.active-trail {
  background: #e6fa37;
}
.contentSingleNavigation ul li ul,
.contentSingleNavigation ul .leaf ul {
  display: none;
}
.contentSingleNavigation .innerWrapper {
  position: relative;
}
.contentSingleNavigation span.select {
  display: block;
  background-color: #e6fa37;
  font-size: 0.72222222rem;
  font-family: 'bt_bold';
  font-weight: normal;
  letter-spacing: 0.05em;
  line-height: 1em;
  padding: 20px 0 18px;
  width: 100%;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  z-index: 1;
  border-radius: 4px;
}
.contentSingleNavigation span.select:after {
  content: ".";
  display: block;
  float: right;
  width: 24px;
  height: 100%;
  margin: 0 10px 0 0;
  text-indent: -9999em;
  background-image: url("../images/icon-tinynav.png");
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .contentSingleNavigation span.select:after {
    background-image: url("../images/icon-tinynav@2x.png");
    background-size: 24px 16px;
  }
}
/* two items */
.contentSingleNavigation ul li:nth-child(1):nth-last-child(2),
.contentSingleNavigation ul li:nth-child(2):nth-last-child(1) {
  width: 50%;
}
/* three items */
.contentSingleNavigation ul li:nth-child(1):nth-last-child(3),
.contentSingleNavigation ul li:nth-child(2):nth-last-child(2),
.contentSingleNavigation ul li:nth-child(3):nth-last-child(1) {
  width: 33.3333%;
}
/* four items */
.contentSingleNavigation ul li:nth-child(1):nth-last-child(4),
.contentSingleNavigation ul li:nth-child(2):nth-last-child(3),
.contentSingleNavigation ul li:nth-child(3):nth-last-child(2),
.contentSingleNavigation ul li:nth-child(4):nth-last-child(1) {
  width: 25%;
}
/* five items */
.contentSingleNavigation ul li:nth-child(1):nth-last-child(5),
.contentSingleNavigation ul li:nth-child(2):nth-last-child(4),
.contentSingleNavigation ul li:nth-child(3):nth-last-child(3),
.contentSingleNavigation ul li:nth-child(4):nth-last-child(2),
.contentSingleNavigation ul li:nth-child(5):nth-last-child(1) {
  width: 20%;
}
.tinynav {
  display: block;
  height: 51px;
  margin: 0 auto 20px;
  position: relative;
  width: 100%;
  -webkit-appearance: none;
  cursor: pointer;
}
.badger {
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin-left: 5px;
  color: #ffffff;
  background-color: #f34a31;
  border-radius: 50%;
  display: inline-flex;
  transform: translateY(-4px);
}
.badger-item {
  vertical-align: top;
}
@media (min-width: 400px) {
  .caseNavigation .arrow {
    left: 20px;
    width: auto;
  }
  .caseNavigation .arrow.next {
    right: 20px;
  }
  .caseNavigation .arrow a {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .caseNavigation .arrow a:hover i {
    -webkit-transform: translate(-7px, 0);
    -moz-transform: translate(-7px, 0);
    -ms-transform: translate(-7px, 0);
    -o-transform: translate(-7px, 0);
    transform: translate(-7px, 0);
  }
  .caseNavigation .arrow a i {
    top: auto;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .caseNavigation .arrow a span {
    display: block;
  }
  .caseNavigation .arrow.next a:hover i {
    -webkit-transform: translate(7px, 0);
    -moz-transform: translate(7px, 0);
    -ms-transform: translate(7px, 0);
    -o-transform: translate(7px, 0);
    transform: translate(7px, 0);
  }
  .caseNavigation.bottom .arrow a span {
    display: block;
  }
}
@media (min-width: 700px) {
  section .contentSingleNavigation {
    margin-top: 5px;
  }
  section .contentSingleNavigation ul {
    display: block;
  }
  section .contentSingleNavigation .tinynav {
    display: none;
  }
  section .contentSingleNavigation span.select {
    display: none;
  }
}
@media (min-width: 800px) {
  .mobileControl {
    display: none;
  }
  #headerNav,
  #secondNav {
    display: block;
  }
}
@media (min-width: 950px) {
  .caseNavigation .nav-inner:after {
    display: block;
  }
  .caseNavigation .arrow {
    left: 0;
    width: 10.64814815%;
  }
  .caseNavigation .arrow.next {
    right: 0;
  }
  .caseNavigation .arrow a span {
    display: none;
  }
  .caseNavigation.bottom .arrow.next {
    right: 20px;
  }
}
@media (min-width: 1300px) {
  .caseNavigation .arrow a span {
    display: block;
  }
}
.place-holder {
  border: 1px dashed #aaa;
  color: #aaa;
  display: block;
  margin: 40px;
  padding: 40px;
  text-align: center;
}
#header {
  background-color: #22202b;
  height: 80px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.lt-ie9 #header {
  min-width: 768px;
}
#header .logo {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}
#header .logo a {
  display: block;
  padding: 20px 20px 21px;
}
#header .logo img {
  height: auto;
  max-width: 100%;
}
.lt-ie9 #header .logo img {
  width: auto;
}
#header .cd-menu-icon {
  /* this span is the central line of the menu icon */
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 28px;
  height: 3px;
  background-color: white;
  -webkit-transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  /* these are the upper and lower lines in the menu icon */
}
#header .cd-menu-icon::before,
#header .cd-menu-icon::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  right: 0;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), top 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 0s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: -moz-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), top 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 0s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), top 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 0s cubic-bezier(0.23, 1, 0.32, 1);
}
#header .cd-menu-icon::before {
  top: -8px;
}
#header .cd-menu-icon::after {
  top: 8px;
}
#header .cd-menu-icon.is-clicked {
  background-color: rgba(255, 255, 255, 0);
}
.android-native #header .cd-menu-icon.is-clicked {
  background-color: #ffffff;
}
#header .cd-menu-icon.is-clicked::before,
#header .cd-menu-icon.is-clicked::after {
  background-color: white;
}
#header .cd-menu-icon.is-clicked::before {
  top: 0;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.android-native #header .cd-menu-icon.is-clicked::before {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
#header .cd-menu-icon.is-clicked::after {
  top: 0;
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
}
.android-native #header .cd-menu-icon.is-clicked::after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.lt-ie9 #header .cd-menu-icon:before,
.lt-ie9 #header .cd-menu-icon:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  right: 0;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), top 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 0s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: -moz-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), top 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 0s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), top 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 0s cubic-bezier(0.23, 1, 0.32, 1);
}
.lt-ie9 #header .cd-menu-icon:before {
  top: -8px;
}
.lt-ie9 #header .cd-menu-icon:after {
  top: 8px;
}
#header ul.language-switcher-locale-url li.active {
  display: none;
}
.node-type-case #header {
  background-color: transparent;
  -webkit-transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.node-type-case.header-bg #header {
  background-color: #22202b;
}
.front #header {
  background-color: transparent;
  -webkit-transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: background-color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.front.header-bg #header {
  background-color: #22202b;
}
@media (min-width: 800px) {
  #header .logo a {
    padding: 20px 36px 21px;
  }
}
#footer {
  background-color: #22202b;
  color: #e6fa37;
  font-size: 0.83333333rem;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  padding: 50px 0;
  position: relative;
}
#footer .widthWrapper {
  padding: 0 30px;
}
.touchevents #footer .widthWrapper {
  opacity: 1 !important;
  transform: translate3d(0, 0, 0) !important;
}
#footer a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: color 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
#footer a:hover {
  color: #ffffff;
}
.lt-ie8 #footer a {
  color: #999;
}
.lt-ie8 #footer a:hover {
  color: #ffffff;
}
#footer h3 {
  color: #ffffff;
  font-size: 0.77777778rem;
  display: block;
  text-transform: uppercase;
  font-family: 'bt_bold';
  font-weight: normal;
  letter-spacing: 0.1em;
  margin-bottom: 4px;
}
#footer p {
  line-height: 1.42857143em;
}
#footer p.address {
  padding-top: 7px;
}
#footer .listing li:first-child {
  border-top: none;
}
#footer .listing li:last-child {
  border-bottom: none;
}
#footer .listing li a {
  display: block;
  padding: 6px 0 3px;
}
#footer .listing li .date {
  display: none;
}
#footer .listing.footerMainmenu li,
#footer .listing.footerCases li,
#footer .listing.footerMainmenu .leaf,
#footer .listing.footerCases .leaf {
  list-style: none;
  list-style-image: none;
  list-style-type: none;
  padding: 0;
}
#footer .listing.social a {
  padding: 14px 0 10px 47px;
  position: relative;
}
#footer .listing.social a i {
  color: #ffffff;
  font-size: 32px;
  left: -10px;
  position: absolute;
  top: 9px;
}
#footer .listing.social a i.icon-drupal {
  font-size: 34px;
  top: 7px;
}
#footer .col {
  float: none;
  margin: 0 auto;
  max-width: 550px;
  padding: 5px 0 0;
  position: relative;
  width: auto;
  z-index: 5;
}
#footer .col:last-child {
  padding-bottom: 0;
}
#footer .colContent {
  margin-bottom: 1.5em;
}
#footer .colContent + h3 {
  margin-top: 8px;
  margin-bottom: 1.5em;
}
#iso_nen_badges {
  width: 140px;
  height: 82px;
}
@media (min-width: 700px) {
  #footer {
    font-size: 0.77777778rem;
    padding: 90px 0;
  }
  #footer .widthWrapper {
    padding: 0;
  }
  #footer h3 {
    display: inline-block;
    font-size: 0.75rem;
  }
  #footer .col {
    float: left;
    margin: 0 2%;
    max-width: none;
    width: 21%;
  }
}
.articleMain {
  padding-top: 20px;
  padding-bottom: 80px;
  position: relative;
}
.articleMain .articleMainHead {
  color: #ffffff;
  font-size: 1rem;
  font-family: 'bt_bold';
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 1.3em;
  margin: 10px auto 50px;
  max-width: 600px;
  text-align: center;
  text-transform: uppercase;
}
.articleMain .articleMainHead > a {
  color: #ffffff;
  text-decoration: none;
}
.articleMain .articleHeader {
  margin: 2em 0 2em;
}
.articleMain .articleHeader h2 {
  font-size: 0.75rem;
  font-family: 'bt_bold';
  font-weight: normal;
  letter-spacing: 0.04em;
  line-height: 1.5em;
  margin-bottom: 1.5em;
}
@media (max-width: 399px) {
  .articleMain .articleHeader h1 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
}
.articleMain.cases {
  padding-top: 65px;
  padding-bottom: 0;
}
.articleMain.cases .articleMainHead {
  color: #22202b;
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 2.44444444rem;
  margin-bottom: 0.75em;
}
.articleMain.cases .articleMainIntro {
  font-family: 'bt_bold';
  font-weight: normal;
  font-size: 1.16666667rem;
  line-height: 1.52380952em;
  margin: 0 auto 3.55555556em;
  max-width: 630px;
  text-align: center;
  text-transform: none;
}
.articleMain.cases .listing-wrapper {
  background-color: #ffffff;
}
.articleMain.case {
  padding-bottom: 0;
}
.articleMain.case .articleHeader {
  margin-top: 2em;
}
.articleMain.case .articleHeader h1 {
  font-size: 1.66666667rem;
  text-transform: uppercase;
}
.articleMain.case .articleHeader h2 {
  font-size: 1.11111111rem;
  font-family: 'bt_regular';
  font-weight: normal;
  margin-bottom: 10px;
}
.articleMain.case .articleHeader h2 a {
  text-decoration: none;
}
@media (min-width: 500px) {
  .articleMain.case .articleHeader h1 {
    font-size: 2.33333333rem;
  }
}
@media (min-width: 700px) {
  .articleMain .articleMainHead {
    font-size: 1.38888889rem;
    margin: 40px auto 90px;
  }
  .articleMain.cases .articleMainHead {
    margin-top: -10px;
  }
}
@media (min-width: 950px) {
  .articleMain.case .articleHeader {
    margin-top: -1em;
  }
}
@media (min-width: 1600px) {
  .articleMain.cases .listing-wrapper {
    padding-bottom: 60px;
  }
}
.front #waypoint-trigger {
  top: 80px;
  position: absolute;
}
.front #frontBranding {
  background-color: #22202b;
  display: block;
  height: 100vh;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 6;
}
.front #frontBranding .brandingWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  width: 100%;
}
.front #frontBranding .brandingWrapper h2 {
  color: #ffffff;
  font-family: 'bt_black';
  font-weight: normal;
  letter-spacing: 0.04em;
  font-size: 1.2rem;
  line-height: 1.66666667em;
  max-width: 703px;
  text-transform: uppercase;
  width: 100%;
}
.front #frontBranding .brandingWrapper .indicator {
  bottom: 17px;
  color: #e6fa37;
  font-size: 1.11111111rem;
  left: 50%;
  padding: 88px 50px 17px;
  position: absolute;
  text-decoration: none;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-animation: indicator 3.5s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-animation: indicator 3.5s cubic-bezier(0.23, 1, 0.32, 1);
  animation: indicator 3.5s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  animation-delay: 2s;
}
.front #frontBranding .brandingWrapper .indicator:hover {
  bottom: 7px;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}
.no-flexbox .front #frontBranding .brandingWrapper h2 {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.front #frontBranding .awards {
  bottom: 20px;
  left: 20px;
  position: absolute;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-animation: fadeInAndMove 1.5s ease-in-out;
  -moz-animation: fadeInAndMove 1.5s ease-in-out;
  animation: fadeInAndMove 1.5s ease-in-out;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  animation-delay: 2s;
}
.front #frontBranding .awards img {
  margin-right: 10px;
  vertical-align: bottom;
}
@media (max-width: 767px) {
  .front #frontBranding .awards img {
    height: 120px;
  }
}
@media (max-width: 500px) {
  .front #frontBranding .awards img {
    height: 100px;
  }
}
@media (max-width: 399px) {
  .front #frontBranding .awards img {
    height: 80px;
  }
}
@media (max-width: 374px) {
  .front #frontBranding .awards img {
    height: 70px;
    margin-right: 5px;
  }
}
.front #content {
  padding: 0;
}
.front .covervid-wrapper {
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.videoautoplay .front .covervid-wrapper#no-poster {
  background-image: none !important;
}
.front .covervid-wrapper .covervid-video {
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: opacity 1.5s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: opacity 1.5s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: opacity 1.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 1.5s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition-delay: 1s;
  -moz-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}
.front .covervid-wrapper .covervid-video.is-done {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
.info.home {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
}
.info.home p {
  font-size: 0.98rem;
  letter-spacing: 0.02em;
  line-height: 2.27272727em;
  margin: 0 auto;
  max-width: 800px;
  padding: 50px 15px;
}
.info.home p a {
  color: #22202b;
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.lt-ie8 .info.home p a {
  display: inline;
  zoom: 1;
}
.info.home p a.image-link {
  display: inline;
}
.info.home p a:hover {
  color: #22202b;
}
.info.home p a:hover::after {
  width: 100%;
}
.info.home p a::before {
  background-color: #e6fa37;
  bottom: 0;
  content: '';
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.info.home p a::after {
  background-color: #e6fa37;
  bottom: 0;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  width: 0;
  z-index: -1;
  -webkit-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.info.home p a::before,
.info.home p a::after {
  bottom: 9px;
}
.info.home p a::after {
  height: calc(100% - 18px);
}
.no-flexbox .info.home {
  display: block;
  padding-top: 30px;
  padding-bottom: 30px;
}
.no-flexbox .info.home p {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.update.home {
  background-color: #e6fa37;
}
.update.home .text {
  padding: 50px 15px;
}
.update.home .text h2 {
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 1.44444444rem;
  line-height: 1.53846154em;
  margin-bottom: 2rem;
}
.update.home .text p {
  font-size: 1.05555556rem;
  line-height: 1.68421053em;
}
.update.home .text a:hover {
  color: #22202b;
}
.update.home .image {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
}
@media (max-width: 479px) {
  .update.home h2 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
}
@media (min-width: 700px) and (max-width: 1024px) {
  .update.home h2 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
}
.facts.home {
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  max-width: 1060px;
  padding: 50px 0 80px;
}
.facts.home .facts-wrapper {
  letter-spacing: -0.31em;
  *letter-spacing: normal;
  *word-spacing: -0.43em;
}
.facts.home .facts-wrapper div {
  display: inline-block;
  margin: 0 15px;
  text-align: center;
  vertical-align: top;
  width: calc(50% - 30px);
  letter-spacing: normal;
  word-spacing: normal;
}
.lt-ie8 .facts.home .facts-wrapper div {
  display: inline;
  zoom: 1;
}
.facts.home .facts-wrapper img {
  display: block;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.facts.home .facts-wrapper hr {
  background-color: #e6fa37;
  border: none;
  height: 5px;
  margin: -5px auto 20px;
  max-width: 100px;
}
.facts.home .facts-wrapper p {
  font-size: 0.8rem;
  line-height: 1.5em;
}
.beta.home {
  align-items: center;
  background-color: #22202b;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 50px 15px;
  position: relative;
  text-align: center;
}
.beta.home .inner {
  margin: 0 auto;
  max-width: 740px;
}
.beta.home h3 {
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 1.66666667rem;
  line-height: 1.2em;
  margin-bottom: 0.7em;
}
.beta.home p {
  font-family: 'bt_light';
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.875em;
  margin-bottom: 1em;
}
.beta.home .button {
  font-size: 1rem;
  letter-spacing: 0.1em;
  padding: 19px 42px 17px;
}
.beta.home .button:hover {
  padding-left: 70px;
  padding-right: 70px;
}
section.cases.home {
  padding: 50px 0;
  text-align: center;
}
section.cases.home .articleMainHead {
  font-size: 0.88888889rem;
  margin: -10px auto 45px;
}
section.cases.home .listing + .button {
  display: inline-block;
  margin-top: 50px;
}
.lt-ie8 section.cases.home .listing + .button {
  display: inline;
  zoom: 1;
}
.colsWrapper.home .colsOneThird {
  font-size: 0.88888889rem;
  line-height: 1.65em;
}
.colsWrapper.home .colsOneThird h2 {
  color: #22202b;
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 1.33333333rem;
  margin-bottom: 0.5em;
}
.colsWrapper.home .colsOneThird p {
  font-size: 0.9375rem;
}
.colsWrapper.home .colsOneThird .vacancies {
  line-height: 24px;
  list-style: disc outside none;
}
.colsWrapper.home .colsOneThird .vacancies li {
  margin-left: 0.9em;
  padding-left: 0.25em;
}
.home .colsOneThird a {
  color: inherit;
}
.home .colsOneThird a.button {
  color: #ffffff;
}
.home .colsOneThird a span {
  text-decoration: none;
}
.colsOneThird .followUs li {
  margin-bottom: 20px;
}
.colsOneThird .followUs li:last-child {
  margin-bottom: 0;
}
.colsOneThird .followUs .image {
  float: left;
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;
}
.colsOneThird .followUs .image img {
  border-radius: 50%;
  -webkit-transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.lt-ie9 .colsOneThird .followUs .image img {
  height: 45px !important;
  width: 45px !important;
}
.colsOneThird .followUs .image a:hover img {
  -webkit-opacity: 0.9;
  -khtml-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=90);
  filter: alpha(opacity=90);
}
.colsOneThird .followUs .socialInfo {
  padding: 0 0 0 60px;
}
.colsOneThird .image {
  width: auto;
}
@media (min-width: 400px) {
  .info.home p {
    font-size: 1.22222222rem;
    padding: 50px 30px;
  }
  .update.home .text {
    padding: 50px 30px;
  }
  .update.home .image {
    min-height: 500px;
  }
  .beta.home {
    padding: 50px 30px;
  }
  .beta.home p {
    font-size: 1.77777778rem;
  }
}
@media (min-width: 500px) {
  section.cases.home .articleMainHead {
    font-size: 1.11111111rem;
  }
}
@media (min-width: 600px) {
  .update.home .col.text {
    padding: 45px;
  }
  .update.home .col.text h2 {
    margin-bottom: 3rem;
  }
  .update.home .col.image {
    min-height: 600px;
  }
  .info.home {
    min-height: 50vh;
  }
  .beta.home {
    min-height: 60vh;
    padding: 50px 45px;
  }
}
@media (min-width: 700px) {
  .front #frontBranding .brandingWrapper {
    padding-right: 30px;
    padding-left: 30px;
  }
  .front #frontBranding .brandingWrapper h2 {
    font-size: 1.4rem;
  }
  .update.home {
    align-content: center;
    align-items: center;
    display: flex;
  }
  .update.home .col {
    flex-shrink: 0;
    width: 50%;
  }
  .update.home .col .inner {
    margin: 0 auto;
    max-width: 450px;
  }
  .update.home .col.image {
    align-self: stretch;
  }
  .no-flexbox .update.home {
    letter-spacing: -0.31em;
    *letter-spacing: normal;
    *word-spacing: -0.43em;
  }
  .no-flexbox .update.home .col {
    display: inline-block;
    min-height: 500px;
    vertical-align: top;
    width: 50%;
    letter-spacing: normal;
    word-spacing: normal;
  }
  .lt-ie8 .no-flexbox .update.home .col {
    display: inline;
    zoom: 1;
  }
  .facts.home {
    max-width: 990px;
  }
  .facts.home .facts-wrapper div {
    width: calc(33.3333333333% - 30px);
  }
  .facts.home .facts-wrapper p {
    font-size: 0.88888889rem;
  }
}
@media (min-width: 800px) {
  .front #frontBranding .brandingWrapper h2 {
    font-size: 1.66666667rem;
  }
  .front #frontBranding .awards {
    bottom: 36px;
    left: 36px;
  }
  .update.home .col.text {
    padding: 55px;
  }
  .beta.home {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (min-width: 992px) {
  .update.home .col.text {
    padding: 100px 75px;
  }
  .update.home .col.image {
    min-height: 600px;
  }
}
@media (min-width: 1200px) {
  .update.home .col.text {
    padding: 150px 90px;
  }
  .update.home .col.image {
    min-height: 710px;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.85;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.85;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.85;
  }
}
@-webkit-keyframes fadeInAndMove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeInAndMove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInAndMove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeInZoomOut {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes fadeInZoomOut {
  0% {
    opacity: 0;
    -moz-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes fadeInZoomOut {
  0% {
    opacity: 0;
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes indicator {
  0% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  5% {
    -webkit-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px);
  }
  10% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  15% {
    -webkit-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px);
  }
  20% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
@-moz-keyframes indicator {
  0% {
    -moz-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  5% {
    -moz-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px);
  }
  10% {
    -moz-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  15% {
    -moz-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px);
  }
  20% {
    -moz-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  100% {
    -moz-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
@keyframes indicator {
  0% {
    transform: translate(-50%, 0);
  }
  5% {
    transform: translate(-50%, 10px);
  }
  10% {
    transform: translate(-50%, 0);
  }
  15% {
    transform: translate(-50%, 10px);
  }
  20% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
.listing-cases {
  margin: 0 auto;
  max-width: 1600px;
}
.listing-cases a {
  display: block;
  position: relative;
  text-decoration: none;
}
.listing-cases a:hover .image::after {
  -webkit-opacity: 0.6;
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=60);
  filter: alpha(opacity=60);
}
.listing-cases a:hover .image img {
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
  transform: scale(1.02);
}
.listing-cases a:hover .info h2 {
  -webkit-transform: translate3d(0, -15px, 0);
  -moz-transform: translate3d(0, -15px, 0);
  -ms-transform: translate3d(0, -15px, 0);
  -o-transform: translate3d(0, -15px, 0);
  transform: translate3d(0, -15px, 0);
}
.listing-cases a:hover .info span {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.listing-cases .image {
  overflow: hidden;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.listing-cases .image::after {
  background-color: transparent;
  background-image: url('../images/blur-cases.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  -webkit-opacity: 0.5;
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  filter: alpha(opacity=50);
  -webkit-transition: opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1);
}
.listing-cases .image img {
  backface-visibility: hidden;
  display: block;
  height: auto;
  max-width: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
  transform-origin: center top;
  -webkit-transform: scale(1.08);
  -moz-transform: scale(1.08);
  -ms-transform: scale(1.08);
  -o-transform: scale(1.08);
  transform: scale(1.08);
  -webkit-transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
}
.listing-cases .info {
  bottom: 9%;
  left: 6%;
  padding-right: 20px;
  position: absolute;
  width: 94%;
}
.listing-cases .info h2 {
  color: #ffffff;
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 1.4rem;
  line-height: 1.2em;
  -webkit-transform: translate3d(0, 30px, 0);
  -moz-transform: translate3d(0, 30px, 0);
  -ms-transform: translate3d(0, 30px, 0);
  -o-transform: translate3d(0, 30px, 0);
  transform: translate3d(0, 30px, 0);
  -webkit-transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
}
.touchevents .listing-cases .info h2 {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
@media (max-width: 399px) {
  .listing-cases .info h2 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
}
.listing-cases .info span {
  color: #ffffff;
  display: block;
  font-family: 'bt_bold';
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.2em;
  text-transform: uppercase;
  transform-origin: 50% 0%;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: translate3d(0, 10px, 0);
  -moz-transform: translate3d(0, 10px, 0);
  -ms-transform: translate3d(0, 10px, 0);
  -o-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
}
.touchevents .listing-cases .info span {
  display: none;
}
.listing-cases .awards {
  left: 6%;
  position: absolute;
  top: 10px;
  width: 94%;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-animation: fadeInAndMove 1.5s ease-in-out;
  -moz-animation: fadeInAndMove 1.5s ease-in-out;
  animation: fadeInAndMove 1.5s ease-in-out;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}
.listing-cases .awards img {
  height: 100px;
  width: auto;
}
.listing-cases .awards img.award-splash {
  -webkit-transform: translate(-11.66666667%, 0);
  -moz-transform: translate(-11.66666667%, 0);
  -ms-transform: translate(-11.66666667%, 0);
  -o-transform: translate(-11.66666667%, 0);
  transform: translate(-11.66666667%, 0);
}
.listing-cases .has-award .image::after {
  background-image: url('../images/blur-cases-award.png');
}
@media (min-width: 400px) {
  .listing-cases .info h2 {
    font-size: 1.66666667rem;
  }
  .listing-cases .info span {
    font-size: 0.83333333rem;
  }
  .listing-cases .awards img {
    height: 120px;
  }
}
@media (min-width: 600px) {
  .listing-cases .awards {
    top: 20px;
  }
  .listing-cases .awards img {
    height: 140px;
  }
}
@media (min-width: 900px) {
  .listing-cases:before,
  .listing-cases:after {
    content: "";
    display: table;
  }
  .listing-cases:after {
    clear: both;
  }
  .lt-ie8 .listing-cases {
    zoom: 1;
  }
  .listing-cases .list-item {
    float: left;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .listing-cases .awards {
    top: 25px;
  }
  .listing-cases .awards img {
    height: 168px;
  }
}
.ticker {
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #22202b;
  margin-bottom: 1.77777778em;
  overflow: hidden;
  position: relative;
}
.ticker .ticker-wrapper {
  white-space: nowrap;
}
.no-js .ticker .ticker-wrapper {
  overflow: auto;
}
.ticker .ticker-item {
  display: inline-block;
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 10rem;
  line-height: 1em;
  padding: 0.332em 0.3em;
  will-change: transform;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.lt-ie8 .ticker .ticker-item {
  display: inline;
  zoom: 1;
}
.ticker .url {
  font-size: 0.8rem;
  left: 50%;
  letter-spacing: 0.1em;
  padding: 14px 32px 12px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.ticker .url:hover {
  padding-left: 70px;
  padding-right: 70px;
}
.ticker.home {
  background-image: url('../images/bg-ticker.jpg');
  color: #ffffff;
  margin-bottom: 0;
}
@media (min-width: 400px) {
  .ticker .ticker-item {
    font-size: 11.5rem;
  }
  .ticker .url {
    font-size: 1rem;
    padding: 19px 42px 17px;
  }
}
@media (min-width: 500px) {
  .ticker .ticker-item {
    font-size: 12.5rem;
  }
}
@media (min-width: 700px) {
  .ticker .ticker-item {
    font-size: 13.88888889rem;
  }
}
.formStyles .form-item {
  margin: 3.2em auto;
  max-width: 650px;
}
.formStyles .question {
  display: block;
  font-family: 'bt_black';
  font-weight: normal;
  letter-spacing: 0.04em;
  font-size: 1.11111111rem;
  line-height: 1.6em;
  text-transform: uppercase;
  padding-bottom: 18px;
  position: relative;
}
.formStyles .question::after {
  background-color: #e6fa37;
  top: -15px;
  content: '';
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  width: 100px;
}
.formStyles .form-checkboxes {
  margin-bottom: 30px;
}
.formStyles .form-checkboxes.checked label {
  background-color: #e6e6e3;
}
.formStyles .form-type-radio {
  display: inline-block;
  margin-right: 6px;
  position: relative;
  vertical-align: top;
}
.formStyles .form-type-radio:last-child {
  margin-right: 0;
}
.formStyles input {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  opacity: 0;
}
.formStyles input.form-yes:checked + label {
  background-color: #28b92f !important;
}
.formStyles input.form-no:checked + label {
  background-color: #ed5239 !important;
}
.formStyles label {
  background-color: #22202b;
  color: #ffffff;
  display: block;
  font-family: 'bt_bold';
  font-weight: normal;
  font-size: 0.83333333rem;
  max-width: 113px;
  padding: 9px 43px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  border-radius: 5px;
  -webkit-transition: background-color 0.15s ease;
  -moz-transition: background-color 0.15s ease;
  -o-transition: background-color 0.15s ease;
  transition: background-color 0.15s ease;
}
.formStyles label:hover {
  background-color: #000000;
  cursor: pointer;
}
.answer-block {
  margin: 0 auto;
  max-width: 650px;
}
.answer-block span {
  display: block;
  font-family: 'bt_regular';
  font-weight: normal;
  margin-bottom: 1.77777778em;
}
.answer-block span:last-child,
.answer-block span:last-of-type {
  margin-bottom: 0;
}
.answer-block span strong,
.answer-block span a {
  font-family: 'bt_bold';
  font-weight: normal;
}
.answer-block .no-result {
  background-color: #f6f6f4;
  padding: 25px 30px;
}
.answer-block .no-result span {
  font-family: 'bt_regular_italic';
  font-weight: normal;
  font-style: italic;
}
.answer-block .result {
  background-color: #e6fa37;
  padding: 25px 30px 30px;
}
div.item-list ul li,
div.item-list ul {
  margin: 0;
}
div.item-list .title {
  font-weight: normal;
}
#infscr-loading div,
#infscr-loading img {
  display: none;
}
.tabs ul.tabs.primary,
ul.editBlock {
  border-bottom: none;
  border-collapse: collapse;
  height: auto;
  line-height: 1em;
  list-style: none outside none;
  margin: 15px 0 20px;
  padding: 0;
  white-space: nowrap;
}
.tabs ul.tabs.primary li a,
ul.editBlock li a {
  background: url('../images/drupal7-buttons.png') repeat-x 0 0 #e9e9e9;
  border: 1px solid;
  border-color: #e4e4e4 #d2d2d2 #b4b4b4;
  color: #5a5a5a;
  cursor: pointer;
  font-family: "Lucida Grande", Verdana, sans-serif;
  font-size: 13px;
  font-weight: normal;
  margin-right: 0.3em;
  padding: 4px 11px;
  text-align: center;
  text-decoration: none !important;
  border-radius: 20px;
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.tabs ul.tabs.primary li a:hover,
ul.editBlock li a:hover {
  border-color: #b4b4b4;
  background-position: left -40px;
  color: #222222;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
  -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
}
.tabs ul.tabs.primary li.active a,
ul.editBlock li.active a,
.tabs ul.tabs.primary li.active a:hover,
ul.editBlock li.active a:hover {
  background-position: left -70px;
  border-color: #aaaaaa;
  color: #000000;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3) inset;
  -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3) inset;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3) inset;
}
body div.messages {
  background-position: 6px 6px;
  /* LTR */
  background-repeat: no-repeat;
  border: none;
  border-bottom: 1px solid #ffffff;
  font-size: 15px;
  line-height: 17px;
  margin: 6px 0 1em;
  padding: 10px 10px 10px 40px;
  /* LTR */
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1) inset;
}
body div.messages a {
  text-decoration: underline;
}
body div.status {
  background-image: url("../images/message-ok.png");
  border-color: #ffffff;
}
body div.messages.status,
body .ok {
  color: #ffffff;
}
body div.messages.status a,
body .ok a,
body div.messages.status a:hover,
body .ok a:hover {
  color: #ffffff;
}
body div.messages.status,
body table tr.ok {
  background-color: #5c9e45;
}
body div.warning {
  background-image: url("../images/message-warning.png");
  border-color: #ffffff;
}
body div.messages.warning,
body .warning {
  color: #333;
  text-shadow: none;
}
body div.messages.warning a,
body .warning a,
body div.messages.warning a:hover,
body .warning a:hover {
  color: #333;
}
body div.messages.warning,
body table tr.warning {
  background-color: #f2d654;
}
body div.messages.error {
  background-image: url("../images/message-warning.png");
  border-color: #a7210a;
}
body div.messages.error,
body .messages.error {
  color: #ffffff;
}
body div.messages.error,
body table tr.error {
  background-color: #f3583e;
}
body div.error p.error {
  color: #ffffff;
}
body div.messages ul {
  margin: 0 0 0 0;
  /* LTR */
  padding: 0;
}
body div.messages ul li {
  list-style-image: none;
}
body div.messages ul li pre {
  word-wrap: break-word;
}
#messages {
  clear: left;
  margin: 0 24px;
}
#messages .messages.error a {
  color: #ffffff;
  text-decoration: underline;
}
.page-user #content {
  padding-top: 90px;
}
.page-user .tabs,
.page-user #block-system-main {
  margin: 0 auto 20px auto;
  max-width: 750px;
  padding: 0 30px;
}
.page-user #user-login .form-item,
.page-user #user-pass .form-item {
  margin: 0 0 1.5em 0;
}
.page-user #user-login label,
.page-user #user-pass label {
  font-size: 17px;
  font-family: 'bt_bold';
  font-weight: normal;
  line-height: 1.3;
}
.page-user #user-login .form-required,
.page-user #user-pass .form-required {
  color: #f3583e;
}
.page-user #user-login input[type="text"],
.page-user #user-pass input[type="text"],
.page-user #user-login input[type="password"],
.page-user #user-pass input[type="password"] {
  border: 1px solid #e1e1e1;
  color: #333333;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  margin: 0 0 5px 0;
  padding: 0 10px;
  width: 300px;
  border-radius: 0;
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.page-user #user-login input[type="text"]:focus,
.page-user #user-pass input[type="text"]:focus,
.page-user #user-login input[type="password"]:focus,
.page-user #user-pass input[type="password"]:focus {
  background-color: #fffbcc;
  border-color: #c5c5c5;
  outline: 0;
}
.page-user #user-login .form-actions,
.page-user #user-pass .form-actions {
  margin: 0;
}
.page-user #user-login .form-actions input[type="submit"],
.page-user #user-pass .form-actions input[type="submit"] {
  background-color: #22202b;
  color: #ffffff !important;
  display: inline-block;
  font-size: 0.83333333rem;
  font-family: 'bt_bold';
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 1em;
  overflow: hidden;
  padding: 18px 22px 16px;
  text-decoration: none !important;
  text-transform: uppercase;
  border-radius: 4px;
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  border: none;
  font-size: 16px;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}
.lt-ie8 .page-user #user-login .form-actions input[type="submit"],
.lt-ie8 .page-user #user-pass .form-actions input[type="submit"] {
  display: inline;
  zoom: 1;
}
.page-user #user-login .form-actions input[type="submit"]:hover,
.page-user #user-pass .form-actions input[type="submit"]:hover {
  background-color: #e3f91e;
  color: #22202b !important;
  cursor: pointer;
  text-decoration: none !important;
}
.page-user #user-login .form-actions input[type="submit"].full,
.page-user #user-pass .form-actions input[type="submit"].full {
  display: block;
  text-align: center;
}
.page-user #user-login .form-actions input[type="submit"].small,
.page-user #user-pass .form-actions input[type="submit"].small {
  font-size: 0.72222222rem;
  padding: 13px 16px;
}
.page-user #user-login .form-actions input[type="submit"].large,
.page-user #user-pass .form-actions input[type="submit"].large {
  font-size: 115%;
}
.page-user #user-login .form-actions input[type="submit"].edit,
.page-user #user-pass .form-actions input[type="submit"].edit {
  background: url('../images/drupal7-buttons.png') repeat-x 0 0 #e9e9e9;
  border: 1px solid;
  border-color: #e4e4e4 #d2d2d2 #b4b4b4;
  color: #5a5a5a !important;
  cursor: pointer;
  font-family: "Lucida Grande", Verdana, sans-serif;
  font-size: 0.72222222rem;
  font-weight: normal;
  margin-right: 0.3em;
  padding: 4px 11px;
  text-align: center;
  text-decoration: none !important;
  text-transform: none;
  border-radius: 20px;
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.page-user #user-login .form-actions input[type="submit"].edit:hover,
.page-user #user-pass .form-actions input[type="submit"].edit:hover {
  background-position: left -40px;
  border-color: #b4b4b4;
  color: #222222 !important;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
  -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
}
.page-user #user-login .form-actions input[type="submit"].secondary,
.page-user #user-pass .form-actions input[type="submit"].secondary {
  background-color: #e6fa37;
  color: #22202b !important;
}
.page-user #user-login .form-actions input[type="submit"].tertiary,
.page-user #user-pass .form-actions input[type="submit"].tertiary {
  background-color: #e6fa37;
  color: #22202b !important;
  padding: 19px 42px 17px;
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
.page-user #user-login .form-actions input[type="submit"].white,
.page-user #user-pass .form-actions input[type="submit"].white {
  background-color: #ffffff;
  color: #22202b !important;
}
.page-user #user-login .form-actions input[type="submit"].white:hover,
.page-user #user-pass .form-actions input[type="submit"].white:hover {
  background-color: #e6fa37;
  color: #22202b !important;
}
.page-user- #content {
  padding-top: 70px;
}
.page-user- .profile h3 {
  border-bottom: none;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0.5em;
}
.page-user- .profile dl {
  font-size: 15px;
  margin-bottom: 1em;
}
.page-user- .profile dl dt {
  font-weight: bold;
}
#sliding-popup {
  bottom: 0;
  transform: translateY(100%);
  transition: transform 0.5s ease;
  font-family: 'bt_regular';
  font-weight: normal;
}
#sliding-popup.sliding-popup--open {
  transform: translateY(0);
}
#sliding-popup.sliding-popup--open .eu-cookie-withdraw-tab {
  transform: translate(0, -100%);
}
#sliding-popup.sliding-popup--closed {
  transform: translateY(100%);
}
#sliding-popup.sliding-popup--closed .eu-cookie-withdraw-tab {
  transform: translate(0, -1em);
}
#sliding-popup.sliding-popup-bottom,
#sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-banner,
.eu-cookie-withdraw-tab {
  background-color: #000000;
}
#sliding-popup .popup-content {
  padding: 0 1rem;
  max-width: 100%;
}
#sliding-popup .popup-content a {
  color: #ffffff;
}
#sliding-popup .popup-content #popup-text {
  font-weight: normal;
  margin-top: 14px;
}
#sliding-popup .popup-content #popup-text p {
  font-size: 0.88888889rem;
  line-height: 1.5em;
  font-weight: normal;
}
#sliding-popup .popup-content #popup-text .find-more-button {
  font-size: 0.88888889rem;
  line-height: 1.5em;
  font-weight: normal;
}
#sliding-popup .popup-content .eu-cookie-compliance-category {
  margin-bottom: 0.5rem;
}
#sliding-popup .popup-content .eu-cookie-compliance-category:last-child {
  margin-bottom: 0;
}
#sliding-popup .popup-content .eu-cookie-compliance-category > div:first-child {
  position: relative;
}
#sliding-popup .popup-content .eu-cookie-compliance-category > div:first-child label {
  font-family: 'bt_bold';
  font-weight: normal;
  padding: 0.25rem 0 0.25rem 1.83333333rem;
}
#sliding-popup .popup-content .eu-cookie-compliance-category > div:first-child label:hover {
  cursor: pointer;
}
#sliding-popup .popup-content .eu-cookie-compliance-category > div:first-child label::before,
#sliding-popup .popup-content .eu-cookie-compliance-category > div:first-child label::after {
  content: "";
  display: block;
  inset-inline-start: 0;
  margin: 0;
  position: absolute;
  top: 0.27777778rem;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
#sliding-popup .popup-content .eu-cookie-compliance-category > div:first-child label::before {
  background: #ffffff;
  border: 1px solid rgba(7, 0, 104, 0.25);
  border-radius: 0.22222222rem;
  box-shadow: none;
  height: 1.33333333rem;
  margin: 0;
  transition-property: background-color, border-color, box-shadow;
  width: 1.33333333rem;
  z-index: 3;
}
#sliding-popup .popup-content .eu-cookie-compliance-category > div:first-child label::after {
  background: transparent center center no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg focusable='false' aria-hidden='true' width='32px' height='32px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline stroke='%2322202b' stroke-width='5' points='5 16 13.6086957 24 27 8'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E%0A");
  background-size: cover;
  height: 1rem;
  inset-inline-start: 0.16666667rem;
  opacity: 0;
  inset-block-start: 0.38888889rem;
  transition-property: opacity;
  width: 1rem;
  z-index: 4;
}
#sliding-popup .popup-content .eu-cookie-compliance-category > div:first-child input[type="checkbox"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
#sliding-popup .popup-content .eu-cookie-compliance-category > div:first-child input[type="checkbox"]:focus + label::before {
  outline: none;
}
#sliding-popup .popup-content .eu-cookie-compliance-category > div:first-child input[type="checkbox"]:focus-visible + label::before {
  outline: 1px solid #5e9ed6;
  outline: 5px auto -webkit-focus-ring-color;
}
#sliding-popup .popup-content .eu-cookie-compliance-category > div:first-child input[type="checkbox"]:checked + label::before {
  border: 1px solid rgba(7, 0, 104, 0.5);
  box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}
#sliding-popup .popup-content .eu-cookie-compliance-category > div:first-child input[type="checkbox"]:checked + label::after {
  opacity: 1;
}
#sliding-popup .popup-content .eu-cookie-compliance-category-description {
  font-size: 0.88888889rem;
  line-height: 1.5em;
  font-weight: normal;
  padding-left: 1.83333333rem;
}
#sliding-popup .popup-content .eu-cookie-compliance-save-preferences-button {
  background-color: #22202b;
  color: #ffffff !important;
  display: inline-block;
  font-size: 0.83333333rem;
  font-family: 'bt_bold';
  letter-spacing: 0.1em;
  line-height: 1em;
  overflow: hidden;
  padding: 18px 22px 16px;
  text-decoration: none !important;
  border-radius: 4px;
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  background-color: #e6fa37;
  color: #22202b !important;
  padding: 19px 42px 17px;
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 0.72222222rem;
  padding: 13px 16px;
  background-image: none;
  border: none;
  box-shadow: none;
  text-shadow: none;
}
.lt-ie8 #sliding-popup .popup-content .eu-cookie-compliance-save-preferences-button {
  display: inline;
  zoom: 1;
}
#sliding-popup .popup-content .eu-cookie-compliance-save-preferences-button:hover {
  background-color: #e3f91e;
  color: #22202b !important;
  cursor: pointer;
  text-decoration: none !important;
}
#sliding-popup .popup-content .eu-cookie-compliance-save-preferences-button.full {
  display: block;
  text-align: center;
}
#sliding-popup .popup-content .eu-cookie-compliance-save-preferences-button.small {
  font-size: 0.72222222rem;
  padding: 13px 16px;
}
#sliding-popup .popup-content .eu-cookie-compliance-save-preferences-button.large {
  font-size: 115%;
}
#sliding-popup .popup-content .eu-cookie-compliance-save-preferences-button.edit {
  background: url('../images/drupal7-buttons.png') repeat-x 0 0 #e9e9e9;
  border: 1px solid;
  border-color: #e4e4e4 #d2d2d2 #b4b4b4;
  color: #5a5a5a !important;
  cursor: pointer;
  font-family: "Lucida Grande", Verdana, sans-serif;
  font-size: 0.72222222rem;
  font-weight: normal;
  margin-right: 0.3em;
  padding: 4px 11px;
  text-align: center;
  text-decoration: none !important;
  text-transform: none;
  border-radius: 20px;
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
#sliding-popup .popup-content .eu-cookie-compliance-save-preferences-button.edit:hover {
  background-position: left -40px;
  border-color: #b4b4b4;
  color: #222222 !important;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
  -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
}
#sliding-popup .popup-content .eu-cookie-compliance-save-preferences-button.secondary {
  background-color: #e6fa37;
  color: #22202b !important;
}
#sliding-popup .popup-content .eu-cookie-compliance-save-preferences-button.tertiary {
  background-color: #e6fa37;
  color: #22202b !important;
  padding: 19px 42px 17px;
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
#sliding-popup .popup-content .eu-cookie-compliance-save-preferences-button.white {
  background-color: #ffffff;
  color: #22202b !important;
}
#sliding-popup .popup-content .eu-cookie-compliance-save-preferences-button.white:hover {
  background-color: #e6fa37;
  color: #22202b !important;
}
#sliding-popup .popup-content #popup-buttons .agree-button {
  background-color: #22202b;
  color: #ffffff !important;
  display: inline-block;
  font-size: 0.83333333rem;
  font-family: 'bt_bold';
  letter-spacing: 0.1em;
  line-height: 1em;
  overflow: hidden;
  padding: 18px 22px 16px;
  text-decoration: none !important;
  border-radius: 4px;
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  background-color: #e6fa37;
  color: #22202b !important;
  padding: 19px 42px 17px;
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 0.72222222rem;
  padding: 13px 16px;
  background-image: none;
  border: none;
  box-shadow: none;
  text-shadow: none;
}
.lt-ie8 #sliding-popup .popup-content #popup-buttons .agree-button {
  display: inline;
  zoom: 1;
}
#sliding-popup .popup-content #popup-buttons .agree-button:hover {
  background-color: #e3f91e;
  color: #22202b !important;
  cursor: pointer;
  text-decoration: none !important;
}
#sliding-popup .popup-content #popup-buttons .agree-button.full {
  display: block;
  text-align: center;
}
#sliding-popup .popup-content #popup-buttons .agree-button.small {
  font-size: 0.72222222rem;
  padding: 13px 16px;
}
#sliding-popup .popup-content #popup-buttons .agree-button.large {
  font-size: 115%;
}
#sliding-popup .popup-content #popup-buttons .agree-button.edit {
  background: url('../images/drupal7-buttons.png') repeat-x 0 0 #e9e9e9;
  border: 1px solid;
  border-color: #e4e4e4 #d2d2d2 #b4b4b4;
  color: #5a5a5a !important;
  cursor: pointer;
  font-family: "Lucida Grande", Verdana, sans-serif;
  font-size: 0.72222222rem;
  font-weight: normal;
  margin-right: 0.3em;
  padding: 4px 11px;
  text-align: center;
  text-decoration: none !important;
  text-transform: none;
  border-radius: 20px;
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
#sliding-popup .popup-content #popup-buttons .agree-button.edit:hover {
  background-position: left -40px;
  border-color: #b4b4b4;
  color: #222222 !important;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
  -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
}
#sliding-popup .popup-content #popup-buttons .agree-button.secondary {
  background-color: #e6fa37;
  color: #22202b !important;
}
#sliding-popup .popup-content #popup-buttons .agree-button.tertiary {
  background-color: #e6fa37;
  color: #22202b !important;
  padding: 19px 42px 17px;
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
#sliding-popup .popup-content #popup-buttons .agree-button.white {
  background-color: #ffffff;
  color: #22202b !important;
}
#sliding-popup .popup-content #popup-buttons .agree-button.white:hover {
  background-color: #e6fa37;
  color: #22202b !important;
}
#sliding-popup .popup-content #popup-buttons .decline-button,
#sliding-popup .popup-content #popup-buttons .eu-cookie-withdraw-button {
  background-color: #22202b;
  color: #ffffff !important;
  display: inline-block;
  font-size: 0.83333333rem;
  font-family: 'bt_bold';
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 1em;
  overflow: hidden;
  padding: 18px 22px 16px;
  text-decoration: none !important;
  text-transform: uppercase;
  border-radius: 4px;
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  font-size: 0.72222222rem;
  padding: 13px 16px;
  background-image: none;
  border: none;
  box-shadow: none;
  text-shadow: none;
}
.lt-ie8 #sliding-popup .popup-content #popup-buttons .decline-button,
.lt-ie8 #sliding-popup .popup-content #popup-buttons .eu-cookie-withdraw-button {
  display: inline;
  zoom: 1;
}
#sliding-popup .popup-content #popup-buttons .decline-button:hover,
#sliding-popup .popup-content #popup-buttons .eu-cookie-withdraw-button:hover {
  background-color: #e3f91e;
  color: #22202b !important;
  cursor: pointer;
  text-decoration: none !important;
}
#sliding-popup .popup-content #popup-buttons .decline-button.full,
#sliding-popup .popup-content #popup-buttons .eu-cookie-withdraw-button.full {
  display: block;
  text-align: center;
}
#sliding-popup .popup-content #popup-buttons .decline-button.small,
#sliding-popup .popup-content #popup-buttons .eu-cookie-withdraw-button.small {
  font-size: 0.72222222rem;
  padding: 13px 16px;
}
#sliding-popup .popup-content #popup-buttons .decline-button.large,
#sliding-popup .popup-content #popup-buttons .eu-cookie-withdraw-button.large {
  font-size: 115%;
}
#sliding-popup .popup-content #popup-buttons .decline-button.edit,
#sliding-popup .popup-content #popup-buttons .eu-cookie-withdraw-button.edit {
  background: url('../images/drupal7-buttons.png') repeat-x 0 0 #e9e9e9;
  border: 1px solid;
  border-color: #e4e4e4 #d2d2d2 #b4b4b4;
  color: #5a5a5a !important;
  cursor: pointer;
  font-family: "Lucida Grande", Verdana, sans-serif;
  font-size: 0.72222222rem;
  font-weight: normal;
  margin-right: 0.3em;
  padding: 4px 11px;
  text-align: center;
  text-decoration: none !important;
  text-transform: none;
  border-radius: 20px;
  -webkit-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
#sliding-popup .popup-content #popup-buttons .decline-button.edit:hover,
#sliding-popup .popup-content #popup-buttons .eu-cookie-withdraw-button.edit:hover {
  background-position: left -40px;
  border-color: #b4b4b4;
  color: #222222 !important;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
  -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
}
#sliding-popup .popup-content #popup-buttons .decline-button.secondary,
#sliding-popup .popup-content #popup-buttons .eu-cookie-withdraw-button.secondary {
  background-color: #e6fa37;
  color: #22202b !important;
}
#sliding-popup .popup-content #popup-buttons .decline-button.tertiary,
#sliding-popup .popup-content #popup-buttons .eu-cookie-withdraw-button.tertiary {
  background-color: #e6fa37;
  color: #22202b !important;
  padding: 19px 42px 17px;
  font-family: 'bt_black';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
#sliding-popup .popup-content #popup-buttons .decline-button.white,
#sliding-popup .popup-content #popup-buttons .eu-cookie-withdraw-button.white {
  background-color: #ffffff;
  color: #22202b !important;
}
#sliding-popup .popup-content #popup-buttons .decline-button.white:hover,
#sliding-popup .popup-content #popup-buttons .eu-cookie-withdraw-button.white:hover {
  background-color: #e6fa37;
  color: #22202b !important;
}
@media (min-width: 601px) {
  #sliding-popup .popup-content {
    margin: 0 auto;
    max-width: 1200px;
    padding: 1rem;
  }
  #sliding-popup .popup-content #popup-text {
    margin-top: 0;
    max-width: none;
    padding-right: 1rem;
  }
  #sliding-popup .popup-content #popup-buttons {
    max-width: none;
  }
}
@media (min-width: 992px) {
  #sliding-popup .popup-content #popup-buttons {
    flex-shrink: 0;
  }
}
.cookie-content-blocker-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}
.cookie-content-blocker__message-wrapper {
  padding: 30px;
  text-align: center;
}
.cookie-content-blocker__message-wrapper button {
  border: none;
}
.eu-cookie-withdraw-tab {
  border: none;
  font-family: 'bt_bold';
  font-weight: normal;
  font-size: 0.77777778rem;
  left: 6em;
  padding: 0.25em 0.75em;
  text-transform: uppercase;
  z-index: 4;
}
@media (min-width: 768px) {
  .eu-cookie-withdraw-tab {
    left: 8em;
  }
}
