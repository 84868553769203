//
// TYPOGRAPHY
//

// BT font
@font-face {
  font-family: 'bt_black';
  src: url('../fonts/brandon_text/BrandonText-Black.woff2') format('woff2'),
       url('../fonts/brandon_text/BrandonText-Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bt_bold';
  src: url('../fonts/brandon_text/BrandonText-Bold.woff2') format('woff2'),
       url('../fonts/brandon_text/BrandonText-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bt_bold_italic';
  src: url('../fonts/brandon_text/BrandonText-Bold.woff2') format('woff2'),
       url('../fonts/brandon_text/BrandonText-Bold.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'bt_regular';
  src: url('../fonts/brandon_text/BrandonText-Regular.woff2') format('woff2'),
       url('../fonts/brandon_text/BrandonText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bt_regular_italic';
  src: url('../fonts/brandon_text/BrandonText-Regular.woff2') format('woff2'),
       url('../fonts/brandon_text/BrandonText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'bt_light';
  src: url('../fonts/brandon_text/BrandonText-Light.woff2') format('woff2'),
       url('../fonts/brandon_text/BrandonText-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.btLight {
  font-family: 'bt_light';
  font-weight: normal;
}
.btRegular {
  font-family: 'bt_regular';
  font-weight: normal;
}
.btItalic {
  font-family: 'bt_regular_italic';
  font-weight: normal;
  font-style: italic;
}
.btBold {
  font-family: 'bt_bold';
  font-weight: normal;
}
.btBoldItalic {
  font-family: 'bt_bold_italic';
  font-weight: normal;
  font-style: italic;
}
.btBlack {
  font-family: 'bt_black';
  font-weight: normal;
  .uppercase;
}

// DEFAULTS
a {
  color: @linkColor;
  &:hover {
    color: @linkColorHover;
  }
}


// HEADINGS
.articleHeader {
  h1 {
    color: @color-secondary;
    .btBlack;
    font-size: 1.8888888889rem;
    line-height: 1.1em;
  }
  h2 {
    color: @color-secondary;
    font-size: 1.1111111111rem;
    line-height: 1.5em;
    margin-bottom: 0;
    text-transform: uppercase;
  }
}

strong {
  .btBold;
}
strong em,
em strong {
  .btBoldItalic;
}

.underline {
  a {
    color: @color-secondary;
    .inline-block();
    position: relative;
    text-decoration: none;
    &.image-link {
      display: inline;
    }
    &:hover {
      color: @color-secondary;
      &::after {
        width: 100%;
      }
    }
    &::before {
      background-color: @color-tertiary;
      bottom: 0;
      content: '';
      display: block;
      height: 3px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
    &::after {
      background-color: @color-tertiary;
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 0;
      z-index: -1;
      .transition(width .35s @ease);
    }
  }
}

.articleContent {
  letter-spacing: 0.015em;
  // Content & listings
  p, ul, ol {
    font-size: @base-font-size;
    letter-spacing: 0.02em !important;
    line-height: @base-line-height;
    margin-bottom: @base-line-height;
  }
  p {
    .underline;
  }
  em {
    .btItalic;
  }
  a {
    color: inherit;
  }
  small {
    font-size: 85%;
    color: #666;
    a {
      color: inherit;
    }
  }
  ol {
    list-style: decimal outside none;
    li {
      margin-left: 1.2em;
      padding-left: 0.25em;
    }
  }
  ul {
    list-style: disc outside none;
    li {
      margin-left: 1.2em;
      padding-left: 0.25em;
    }
  }
  // Headings
  h1, h2, h3, h4, h5, h6 {
    .btBlack;
    font-size: 1.1111111111rem;
    margin-bottom: 0.2em;
  }
  @media (max-width: 767px) {
    h1, h2 {
      .hyphenate;
    }
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.6666666667rem;
  }
  h3 {
    text-transform: none !important;
    font-size: 1.6em !important;
    line-height: 1.1em !important;
    margin-bottom: 0.7em !important;
  }
  .intro {
    font-size: 1.15rem;
    .btBold;
    text-transform: none;
  }
  blockquote {
    margin-top: 3em;
    margin-bottom: 2em;
    p {
      position: relative;
    }
    .quotation-mark {
      color: @color-tertiary;
      display: inline-block;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 190px;
      font-weight: bold;
      vertical-align: middle;
      width: 40px;
      &::before {
        content: "\2018";
        display: block;
        height: 0;
        .translate(-18px, 5px);
        .firefox & {
          .translate(-18px, 18px);
        }
      }
    }
    .quote-text {
      background-color: @color-tertiary;
      box-shadow: 10px 0 0 @color-tertiary, -10px 0 0 @color-tertiary;
      color: @color-secondary;
      display: inline;
      .btBold;
      font-size: 1.6rem;
      line-height: 1.5em;
      padding: 5px 0;
      margin-bottom: 0;
      -webkit-box-decoration-break: clone;
      -ms-box-decoration-break: clone;
      -o-box-decoration-break: clone;
      box-decoration-break: clone;
    }
    .quote-text-small {
      background-color: @color-tertiary;
      box-shadow: 10px 0 0 @color-tertiary, -10px 0 0 @color-tertiary;
      color: @color-secondary;
      display: inline;
      .btBold;
      font-size: 1.3rem;
      line-height: 1.5em;
      padding: 5px 0;
      margin-bottom: 0;
      -webkit-box-decoration-break: clone;
      -ms-box-decoration-break: clone;
      -o-box-decoration-break: clone;
      box-decoration-break: clone;
    }
    .quote-source {
      color: rgba(0, 0, 0, 0.6);
      display: inline-block;
      .btRegular;
      font-size: 85%;
      line-height: 1.5em;
      margin-bottom: 0;
      margin-top: 20px;
      padding: 5px 0;
      &::before {
        content: '\2013';
        margin-right: 5px;
      }
    }
  }
}
.related {
  .inner > h3 {
    .btBlack;
    font-size: 1.1111111111rem;
    margin: 40px 0;
  }
}

// CONTENT BLOCKS
.contentBlocks {
  .contentBlock {
    h2 {
      font-size: 1.3333333333rem;
      font-weight: bold;
    }
  }
}

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@media (min-width: @breakpointMobileSmall) {
  .articleHeader {
    h1 {
      font-size: 2.4444444444rem;
    }
  }
  .articleContent {
    blockquote {
      .quotation-mark {
        width: 30px;
        &::before {
          .translate(-33px, 5px);
          .firefox & {
            .translate(-33px, 18px);
          }
        }
      }
      p {
        font-size: 1.8888888889rem;
      }
    }
  }
}

@media (min-width: 768px) {
  .articleContent {
    blockquote {
      .quotation-mark {
        &::before {
          .translate(-60px, 5px);
          .firefox & {
            .translate(-60px, 18px);
          }
        }
      }
      .quote-text,
      .quote-text-small {
        left: -30px;
      }
    }
  }
}
