//
// HEADER
//

#header {
  background-color: @color-secondary;
  height: 80px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  .translateZ(0);
  .lt-ie9 & {
    min-width: 768px;
  }
  .logo {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    a {
      display: block;
      padding: 20px 20px 21px;
    }
    img {
      height: auto;
      max-width: 100%;
      .lt-ie9 & {
        width: auto;
      }
    }
  }
  .cd-menu-icon {
    /* this span is the central line of the menu icon */
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 28px;
    height: 3px;
    background-color: white;
    -webkit-transition: background-color .35s @ease;
    -moz-transition: background-color .35s @ease;
    transition: background-color .35s @ease;
    /* these are the upper and lower lines in the menu icon */
  }
  .cd-menu-icon::before,
  .cd-menu-icon::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    right: 0;
    -webkit-transition: -webkit-transform .35s @ease, top .35s @ease, background-color 0s @ease;
    -moz-transition: -moz-transform .35s @ease, top .35s @ease, background-color 0s @ease;
    transition: transform .35s @ease, top .35s @ease, background-color 0s @ease;
  }
  .cd-menu-icon::before {
    top: -8px;
  }
  .cd-menu-icon::after {
    top: 8px;
  }
  .cd-menu-icon.is-clicked {
    background-color: rgba(255, 255, 255, 0);
    .android-native & {
      background-color: @white;
    }
  }
  .cd-menu-icon.is-clicked::before,
  .cd-menu-icon.is-clicked::after {
    background-color: white;
  }
  .cd-menu-icon.is-clicked::before {
    top: 0;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    .android-native & {
      .rotate(0deg);
    }
  }
  .cd-menu-icon.is-clicked::after {
    top: 0;
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
    .android-native & {
      .rotate(0deg);
    }
  }
  .lt-ie9 & {
    .cd-menu-icon:before,
    .cd-menu-icon:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: white;
      right: 0;
      -webkit-transition: -webkit-transform .35s @ease, top .35s @ease, background-color 0s @ease;
      -moz-transition: -moz-transform .35s @ease, top .35s @ease, background-color 0s @ease;
      transition: transform .35s @ease, top .35s @ease, background-color 0s @ease;
    }
    .cd-menu-icon:before {
      top: -8px;
    }
    .cd-menu-icon:after {
      top: 8px;
    }
  }
  // DRUPAL language switch
  ul.language-switcher-locale-url {
    li.active {
      display: none;
    }
  }
  // NODE TYPE CASE
  .node-type-case & {
    background-color: transparent;
    .transition(background-color .35s @ease);
  }
  .node-type-case.header-bg & {
    background-color: @color-secondary;
  }
  // FRONT
  .front & {
    background-color: transparent;
    .transition(background-color .35s @ease);
  }
  .front.header-bg & {
    background-color: @color-secondary;
  }
}

@media (min-width: @breakpointMobileLarge) {
  #header {
    .logo {
      a {
        padding: 20px 36px 21px;
      }
    }
  }
}