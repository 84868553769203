//
// ANIMATION
//

// FADEIN
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.85;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.85;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.85;
  }
}

// FADEINANDMOVE
@-webkit-keyframes fadeInAndMove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeInAndMove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInAndMove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// FADEIN ZOOMOUT
@-webkit-keyframes fadeInZoomOut {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes fadeInZoomOut {
  0% {
    opacity: 0;
    -moz-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fadeInZoomOut {
  0% {
    opacity: 0;
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

// INDICATOR
@-webkit-keyframes indicator {
  0% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  5% {
    -webkit-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px);
  }
  10% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  15% {
    -webkit-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px);
  }
  20% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}

@-moz-keyframes indicator {
  0% {
    -moz-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  5% {
    -moz-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px);
  }
  10% {
    -moz-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  15% {
    -moz-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px);
  }
  20% {
    -moz-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  100% {
    -moz-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}

@keyframes indicator {
  0% {
    transform: translate(-50%, 0);
  }
  5% {
    transform: translate(-50%, 10px);
  }
  10% {
    transform: translate(-50%, 0);
  }
  15% {
    transform: translate(-50%, 10px);
  }
  20% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}