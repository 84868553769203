//
// MISC
//

// PLACE-HOLDER
.place-holder {
  border: 1px dashed #aaa;
  color: #aaa;
  display: block;
  margin: 40px;
  padding: 40px;
  text-align: center;
}
