//
// SCAFFOLDING
//

* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: @base-font-size;
  height: 100% !important;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: @color-secondary;
  color: @textColor;
  font-family: @font-family-sans-serif;
  font-size: @base-font-size;
  height: 100%;
  line-height: @base-line-height;
  &.logged-in {
    padding-top: 30px;
    #header {
      top: 30px;
    }
  }
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: @linkColor;
  transition: color .35s @ease;
  &:hover {
    color: @linkColorHover;
  }
  &:focus {
    color: @linkColor;
  }
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

.img-circle {
  border-radius: 50%;
}

.element-invisible {
  display: none;
}

.alignLeft {
  text-align: right;
}
.alignRight {
  text-align: right;
}
.alignCenter {
  text-align: center;
}

*::selection {
  background: #bfdfff;
}
*::-moz-selection {
  background: #bfdfff;
}
