/* --- TOKEN IMAGES responsive--- */

@tokenImageGutter: 3%;

.tokenImage,
.tokenSlider {
  display: block;
  float: left;
  margin: 6px @tokenImageGutter 25px 0;
  width: 50%-@tokenImageGutter/2; /* default is gelijk aan medium */
  img {
    display: block;
    height: auto;
    margin: 0 auto;
    width: 100%;
  }
  .caption {
    color: #999;
    display: block;
    font-size: 0.75rem;
    line-height: 1.2em;
    padding: 6px 0 0 2px;
    margin: 0 auto;
    text-align: left;
    a,
    a:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
  &.mini {
    width: 25%-@tokenImageGutter/2;
    }
  &.small {
    width: 33.3333%-@tokenImageGutter/2;
    }
  &.medium {
    width: 50%-@tokenImageGutter/2;
    }
  &.large {
    width: 66.6666%-@tokenImageGutter/2;
    }
  &.full {
    width: 100%;
  }

  &.right {
    float: right;
    margin: 6px 0 25px @tokenImageGutter;
  }

  &.full,
  &.center {
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;
    margin-bottom: 3em;
  }
}


/* mobile viewport */
@temporary: (@breakpointMobile)-150px;
@media (max-width: @temporary) {

// prevent images becoming too small

  .tokenImage,
  .tokenSlider {
    &.mini {
      width: 35%;
    }
    &.small {
      width: 45%;
    }
    &.medium {
      width: 55%;
    }
  }

} // .@media


/* mobileSmall viewport */

@media (max-width: @breakpointMobileSmall) {

  .tokenImage,
  .tokenSlider {
    &.mini,
    &.small,
    &.medium,
    &.large,
    &.full {
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
    &.mini,
    &.small {
      width: 50%;
    }
    &.medium {
      width: 80%;
    }
    &.large {
      width: 100%;
    }
  }

  .tokenSlider.small {
    width: 65%;
  }

} // .@media