//
// NAVIGATION
//

// NAVIGATION
#headerNav,
#secondNav {
  display: none;
  overflow: hidden;
  ul {
    li {
      .inline-block();
      list-style: none;
      list-style-image: none;
      list-style-type: none;
      margin: 0 11px;
      padding: 0;
      a {
        color: @white;
        display: block;
        font-size: 0.75rem;
        .btBold;
        letter-spacing: 0.1em;
        line-height: 5.9259259259em;
        padding: 0;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        &:after {
          background-color: @color-tertiary;
          content: '';
          display: block;
          height: 0;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          .transition(height .35s @ease);
        }
        &:hover,
        &.active,
        &.active-trail {
          &:after {
            height: 6px;
          }
        }
        // NODE TYPE CASE
        .node-type-case & {
          color: @white;
          .transition(color .35s @ease);
        }
        .node-type-case.header-bg & {
          color: @white;
        }
      }
    }
  }
}
#headerNav {
  margin-left: 0;
  ul {
     text-align: center;
  }
}
#secondNav {
  position: absolute;
  right: 20px;
  top: 0;
  ul {
    li {
      a:after {
        display: none;
      }
    }
  }
}

// MOBILE NAVIGATION
.mobileNavEffect {
  background-color: @color-tertiary;
  content: '';
  display: block;
  height: 0;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 7;
}
#mobileNav {
  background-color: @color-secondary;
  display: block;
  height: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8;
  ul.language-switcher-locale-url {
    li.active {
      display: none;
    }
  }
  ul {
    li {
      list-style: none;
      list-style-image: none;
      list-style-type: none;
      margin: 0;
      padding: 0;
      a {
        color: @color-tertiary;
        display: block;
        font-size: 0.8333333333rem;
        .btBold;
        line-height: 1em;
        letter-spacing: 0.1em;
        padding: 19px 0 16px 20px;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
          color: @white;
          text-decoration: none;
        }
      }
      i {
        color: @white;
        .inline-block();
        font-size: 32px;
        margin-right: 10px;
        vertical-align: sub;
      }
    }
  }
  .menu {
    padding-top: 80px;
    li:first-child {
      margin-top: -10px;
    }
  }
}

// MOBILE NAV CONTROL
.mobileControl {
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  a {
    color: @white;
    display: block;
    font-size: 1.1111111111rem;
    height: 100%;
    width: 60px;
    i {
      left: 50%;
      position: absolute;
      top: 50%;
      .translate(-50%, -50%);
      &.icon-close {
        display: none;
      }
      &.icon-menu {
        display: block;
      }
    }
    .mobilenav-is-shown & {
      color: @white;
      i {
        &.icon-close {
          display: block;
        }
        &.icon-menu {
          display: none;
        }
      }
    }
    .node-type-case & {
      color: @white;
    }
    .node-type-case.header-bg & {
      color: @color-tertiary;
    }
    .node-type-case.header-bg.mobilenav-is-shown & {
      color: @white;
    }
  }
}

.caseNavigation {
  bottom: 0;
  height: 50px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 5;
  .nav-inner {
    margin: 0 auto;
    max-width: 1296px;
    position: relative;
    &:after {
      background-color: @white;
      content: '';
      display: none;
      height: 50px;
      left: 10.648148148%;
      position: absolute;
      top: 0;
      width: 78.703703704%;
    }
  }
  .arrow {
    height: 50px;
    left: 7px;
    position: absolute;
    width: 30px;
    z-index: 100;
    a {
      display: block;
      height: 100%;
      text-align: center;
      text-decoration: none;
      position: relative;
      &:hover {
        i {
          .translate(-7px, -50%);
        }
      }
      span {
        color: @white;
        display: none;
        font-size: 0.7777777778rem;
        padding: 0 5px;
        .btBold;
        .uppercase;
      }
      i {
        color: @white;
        .inline-block();
        font-size: 1.1rem;
        position: relative;
        top: 50%;
        .transition(all .35s @ease);
        .translate(0, -50%);
      }
    }
    &.next {
      left: auto;
      right: 7px;
      &:hover {
        i {
          .translate(7px, -50%);
        }
      }
      span {
        padding-left: 10px;
      }
    }
  }
  &.bottom {
    margin-bottom: 30px;
    position: relative;
    &:hover {
      .arrow {
        .opacity(1);
      }
    }
    .arrow {
      .opacity(0.5);
      .transition(opacity .35s @ease);
      span, i {
        color: @black;
      }
    }
  }
}

// Content single navigation
.contentSingleNavigation {
  margin: 0 auto;
  text-align: center;
  position: relative;
  ul {
    display: none;
    background: #edeeea;
    margin: 1em auto 3em;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    li,
    .leaf {
      float: left;
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 25%;
      a {
        color: @textColor;
        display: block;
        font-size: 0.7777777778rem;
        .btBold;
        letter-spacing: 0.05em;
        padding: 30px 0 28px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        .transition(background .35s @ease);
        &:hover {
          background: #e2e4de;
        }
        &.active,
        &.active-trail {
          background: @color-tertiary;
        }
      }
      // Submenu
      ul {
        display: none;
      }
    }
  }
   .innerWrapper {
    position: relative;
  }
  // Span select
  span.select {
    display: block;
    background-color: @color-tertiary;
    font-size: 0.7222222222rem;
    .btBold;
    letter-spacing: 0.05em;
    line-height: 1em;
    padding: 20px 0 18px;
    width: 100%;
    z-index: 1;
    position: absolute;
    text-transform: uppercase;
    top: 0;
    z-index: 1;
    border-radius: 4px;
    &:after {
      content: ".";
      display: block;
      float: right;
      width: 24px;
      height: 100%;
      margin: 0 10px 0 0;
      text-indent: -9999em;
      background-image: url("../images/icon-tinynav.png");
      @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
        background-image: url("../images/icon-tinynav@2x.png");
        background-size: 24px 16px;
        }
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      right: 0;
      top: 0;
      .opacity(0.25);
    }
  }
}
/* two items */
.contentSingleNavigation ul li:nth-child(1):nth-last-child(2),
.contentSingleNavigation ul li:nth-child(2):nth-last-child(1) {
    width: 50%;
}
/* three items */
.contentSingleNavigation ul li:nth-child(1):nth-last-child(3),
.contentSingleNavigation ul li:nth-child(2):nth-last-child(2),
.contentSingleNavigation ul li:nth-child(3):nth-last-child(1) {
    width: 33.3333%;
}
/* four items */
.contentSingleNavigation ul li:nth-child(1):nth-last-child(4),
.contentSingleNavigation ul li:nth-child(2):nth-last-child(3),
.contentSingleNavigation ul li:nth-child(3):nth-last-child(2),
.contentSingleNavigation ul li:nth-child(4):nth-last-child(1) {
    width: 25%;
}
/* five items */
.contentSingleNavigation ul li:nth-child(1):nth-last-child(5),
.contentSingleNavigation ul li:nth-child(2):nth-last-child(4),
.contentSingleNavigation ul li:nth-child(3):nth-last-child(3),
.contentSingleNavigation ul li:nth-child(4):nth-last-child(2),
.contentSingleNavigation ul li:nth-child(5):nth-last-child(1) {
    width: 20%;
}
// TinyNav (dropdown menu)
.tinynav {
  display: block;
  height: 51px;
  margin: 0 auto 20px;
  position: relative;
  width: 100%;
  -webkit-appearance: none;
  cursor: pointer;
}

// Vacancy badger
.badger {
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin-left: 5px;
  color: @white;
  background-color: @color-primary;
  border-radius: 50%;
  display: inline-flex;
  transform: translateY(-4px);
}
.badger-item {
  vertical-align: top;
}

@media (min-width: @breakpointMobileSmall) {
  .caseNavigation {
    .arrow {
      left: 20px;
      width: auto;
      &.next {
        right: 20px;
      }
      a {
        align-items: center;
        display: flex;
        justify-content: center;
        &:hover {
          i {
            .translate(-7px, 0);
          }
        }
        i {
          top: auto;
          .translate(0, 0);
        }
        span {
          display: block;
        }
      }
      &.next a:hover {
        i {
          .translate(7px, 0);
        }
      }
    }
    &.bottom {
      .arrow {
        a {
          span {
            display: block;
          }
        }
      }
    }
  }
}

@media (min-width: @breakpointMobile) {
  section {
    .contentSingleNavigation {
      margin-top: 5px;
      ul {
        display: block;
      }
      .tinynav {
        display: none;
      }
      span.select {
        display: none;
      }
    }
  }
}

@media (min-width: @breakpointMobileLarge) {
  .mobileControl {
    display: none;
  }
  #headerNav,
  #secondNav {
    display: block;
  }
}

@media (min-width: 950px) {
  .caseNavigation {
    .nav-inner {
      &:after {
        display: block;
      }
    }
    .arrow {
      left: 0;
      width: 10.648148148%;
      &.next {
        right: 0;
      }
      a {
        span {
          display: none;
        }
      }
    }
    &.bottom {
      .arrow {
        &.next {
          right: 20px;
        }
      }
    }
  }
}

@media (min-width: 1300px) {
  .caseNavigation {
    .arrow {
      a {
        span {
          display: block;
        }
      }
    }
  }
}
