//
// FORMS
// AVG - Other forms in drupal.less
//

.formStyles {
  .form-item {
    margin: (@base-line-height * 1.8) auto;
    max-width: 650px;
  }
  .question {
    display: block;
    .btBlack;
    font-size: 1.1111111111rem;
    line-height: 1.6em;
    text-transform: uppercase;
    padding-bottom: 18px;
    position: relative;
    &::after {
      background-color: @color-tertiary;
      top: -15px;
      content: '';
      display: block;
      height: 3px;
      left: 0;
      position: absolute;
      width: 100px;
    }
  }
  .form-checkboxes {
    margin-bottom: 30px;
    &.checked {
      label {
        background-color: #e6e6e3;
      }
    }
  }
  .form-type-radio {
    display: inline-block;
    margin-right: 6px;
    position: relative;
    vertical-align: top;
    &:last-child {
      margin-right: 0;
    }
  }
  input {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    opacity: 0;
    &.form-yes:checked + label {
      background-color: #28b92f !important;
    }
    &.form-no:checked + label {
      background-color: #ed5239 !important;
    }
  }
  label {
    background-color: @color-secondary;
    color: @white;
    display: block;
    .btBold;
    font-size: 0.8333333333rem;
    max-width: 113px;
    padding: 9px 43px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    z-index: 1;
    border-radius: 5px;
    .transition(background-color .15s ease);
    &:hover {
      background-color: @black;
      cursor: pointer;
    }
  }
}

.answer-block {
  margin: 0 auto;
  max-width: 650px;
  span {
    display: block;
    .btRegular;
    margin-bottom: @base-line-height;
    &:last-child,
    &:last-of-type {
      margin-bottom: 0;
    }
    strong, a {
      .btBold;
    }
  }
  .no-result {
    background-color: #f6f6f4;
    padding: 25px 30px;
    span {
      .btItalic;
    }
  }
  .result {
    background-color: @color-tertiary;
    padding: 25px 30px 30px;
  }
}
