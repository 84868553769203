//
// ARTICLE
//

// ARTICLE MAIN (overview en singles)
.articleMain {
  padding-top: 20px;
  padding-bottom: 80px;
  position: relative;
  .articleMainHead {
    color: @white;
    font-size: 1rem;
    .btBold;
    letter-spacing: 0.1em;
    line-height: 1.3em;
    margin: 10px auto 50px;
    max-width: 600px;
    text-align: center;
    text-transform: uppercase;
    > a {
      color: @white;
      text-decoration: none;
    }
  }
  .articleHeader {
    margin: 2em 0 2em;
    h2 {
      font-size: 0.75rem;
      .btBold;
      letter-spacing: 0.04em;
      line-height: 1.5em;
      margin-bottom: 1.5em;
    }
    h1 {
      @media (max-width: 399px) {
        .hyphenate;
      }
    }
  }
}

// CASES OVERVIEW
.articleMain.cases {
  padding-top: 65px;
  padding-bottom: 0;
  .articleMainHead {
    color: @color-secondary;
    .btBlack;
    font-size: 2.4444444444rem;
    margin-bottom: 0.75em;
  }
  .articleMainIntro {
    .btBold;
    font-size: 1.1666666667rem;
    line-height: 1.5238095238em;
    margin: 0 auto @base-line-height*2;
    max-width: 630px;
    text-align: center;
    text-transform: none;
  }
  .listing-wrapper {
    background-color: @white;
    //padding-bottom: 80px;
  }
}

// CASES SINGLE
.articleMain.case {
  padding-bottom: 0;
  .articleHeader {
    margin-top: 2em;
    h1 {
      font-size: 1.6666666667rem;
      text-transform: uppercase;
    }
    h2 {
      font-size: 1.1111111111rem;
      .btRegular;
      margin-bottom: 10px;
      a {
        text-decoration: none;
      }
    }
  }
}

@media (min-width: 500px) {
  .articleMain.case {
    .articleHeader {
      h1 {
        font-size: 2.3333333333rem;
      }
    }
  }
}

@media (min-width: @breakpointMobile) {
  .articleMain {
    .articleMainHead {
      font-size: 1.3888888889rem;
      margin: 40px auto 90px;
    }
  }
  .articleMain.cases {
    .articleMainHead {
      margin-top: -10px;
    }
  }
}

@media (min-width: 950px) {
  .articleMain.case {
    .articleHeader {
      margin-top: -1em;
    }
  }
}

@media (min-width: 1600px) {
  .articleMain.cases {
    .listing-wrapper {
      padding-bottom: 60px;
    }
  }
}