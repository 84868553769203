//
// FOOTER
//

#footer {
  background-color: @color-secondary;
  color: @color-tertiary;
  font-size: 0.8333333333rem;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  padding: 50px 0;
  position: relative;
  .widthWrapper {
    padding: 0 30px;
    .touchevents & {
      opacity: 1 !important;
      transform: translate3d(0, 0, 0) !important;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
    .transition(color .35s @ease);
    &:hover {
      color: @white;
    }
    .lt-ie8 & {
      color: #999;
      &:hover {
        color: @white;
      }
    }
  }
  h3 {
    color: @white;
    font-size: 0.7777777778rem;
    display: block;
    text-transform: uppercase;
    .btBold;
    letter-spacing: 0.1em;
    margin-bottom: 4px;
  }
  p {
    line-height: 1.4285714286em;
  }
  p.address {
    padding-top: 7px;
  }
  .listing {
    li {
      &:first-child {
        border-top: none;
      }
      &:last-child {
        border-bottom: none;
      }
      a {
        display: block;
        padding: 6px 0 3px;
      }
      .date {
        display: none;
      }
    }
    &.footerMainmenu,
    &.footerCases {
      li,
      .leaf {
        list-style: none;
        list-style-image: none;
        list-style-type: none;
        padding: 0;
      }
    }
    &.social {
      a {
        padding: 14px 0 10px 47px;
        position: relative;
        i {
          color: @white;
          font-size: 32px;
          left: -10px;
          position: absolute;
          top: 9px;
          &.icon-drupal {
            font-size: 34px;
            top: 7px;
          }
        }
      }
    }
  }
  .col {
    float: none;
    margin: 0 auto;
    max-width: 550px;
    padding: 5px 0 0;
    position: relative;
    width: auto;
    z-index: 5;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .colContent {
    margin-bottom: 1.5em;
    + h3 {
      margin-top: 8px;
      margin-bottom: 1.5em;
    }
  }
}
#iso_nen_badges {
  width: 140px;
  height: 82px;
}

@media (min-width: @breakpointMobile) {
  #footer {
    font-size: 0.7777777778rem;
    padding: 90px 0;
    .widthWrapper {
      padding: 0;
    }
    h3 {
      display: inline-block;
      font-size: 0.75rem;
    }
    .col {
      float: left;
      margin: 0 2%;
      max-width: none;
      width: 21%;
    }
  }
}
