//
// HOME
//

.front {
  // TRIGGER FOR STICKY HEADER BG
  #waypoint-trigger {
    top: 80px;
    position: absolute;
  }
  // VIDEO BG
  #frontBranding {
    background-color: @color-secondary;
    display: block;
    height: 100vh;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 6;
    .brandingWrapper {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      margin: 0 auto;
      padding: 0 15px;
      position: relative;
      width: 100%;
      h2 {
        color: @white;
        .btBlack;
        font-size: 1.2rem;
        line-height: 1.6666666667em;
        max-width: 703px;
        text-transform: uppercase;
        width: 100%;
      }
      .indicator {
        bottom: 17px;
        color: @color-tertiary;
        font-size: 1.1111111111rem;
        left: 50%;
        padding: 88px 50px 17px;
        position: absolute;
        text-decoration: none;
        .translate(-50%, 0);
        .transition(all .35s @ease);
        -webkit-animation: indicator 3.5s @ease;
        -moz-animation: indicator 3.5s @ease ;
        animation: indicator 3.5s @ease;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-delay: 2s;
        -moz-animation-delay: 2s;
        animation-delay: 2s;
        &:hover {
          bottom: 7px;
          -webkit-animation-play-state: paused;
          -moz-animation-play-state: paused;
          -o-animation-play-state: paused;
          animation-play-state: paused;
        }
      }
      .no-flexbox & {
        h2 {
          left: 50%;
          position: absolute;
          top: 50%;
          .translate(-50%, -50%);
        }
      }
    }
    .awards {
      bottom: 20px;
      left: 20px;
      position: absolute;
      .opacity(0);
      -webkit-animation: fadeInAndMove 1.5s ease-in-out;
      -moz-animation: fadeInAndMove 1.5s ease-in-out ;
      animation: fadeInAndMove 1.5s ease-in-out;
      -webkit-animation-iteration-count: 1;
      -moz-animation-iteration-count: 1;
      animation-iteration-count: 1;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-delay: 2s;
      -moz-animation-delay: 2s;
      animation-delay: 2s;
      img {
        margin-right: 10px;
        vertical-align: bottom;
        @media (max-width: 767px) {
          height: 120px;
        }
        @media (max-width: 500px) {
          height: 100px;
        }
        @media (max-width: 399px) {
          height: 80px;
        }
        @media (max-width: 374px) {
          height: 70px;
          margin-right: 5px;
        }
      }
    }
  }
  #content {
    padding: 0;
  }
  .covervid-wrapper {
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &#no-poster {
      .videoautoplay & {
        background-image: none !important;
      }
    }
    .no-videoautoplay & {
      .covervid-video {
        //display: none;
      }
    }
    .covervid-video {
      .opacity(0);
      .transition(opacity 1.5s @ease);
      .transition-delay(1s);
      &.is-done {
        .opacity(1);
      }
    }
  }
}

// INFO
.info.home {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  p {
    font-size: 0.98rem;
    letter-spacing: 0.02em;
    line-height: 2.2727272727em;
    margin: 0 auto;
    max-width: 800px;
    padding: 50px 15px;
    .underline;
    a::before,
    a::after {
      bottom: 9px;
    }
    a::after {
      height: ~"calc(100% - 18px)";
    }
  }
  .no-flexbox & {
    display: block;
    padding-top: 30px;
    padding-bottom: 30px;
    p {
      left: 50%;
      position: absolute;
      top: 50%;
      .translate(-50%, -50%);
    }
  }
}

// UPDATE
.update.home {
  background-color: @color-tertiary;
  .text {
    padding: 50px 15px;
    h2 {
      .btBlack;
      font-size: 1.4444444444rem;
      line-height: 1.5384615385em;
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.0555555556rem;
      line-height: 1.6842105263em;
    }
    a:hover {
      color: @color-secondary;
    }
  }
  .image {
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
  }
  @media (max-width: 479px) {
    h2 {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
    }
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    h2 {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
    }
  }
}

// FACTS
.facts.home {
  background-color: @white;
  margin-left: auto;
  margin-right: auto;
  max-width: 1060px;
  padding: 50px 0 80px;
  .facts-wrapper {
    .spacing();
    div {
      .inline-block();
      margin: 0 15px;
      text-align: center;
      vertical-align: top;
      width: ~"calc(50% - 30px)";
      .spacing-normal();
    }
    img {
      display: block;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }
    hr {
      background-color: @color-tertiary;
      border: none;
      height: 5px;
      margin: -5px auto 20px;
      max-width: 100px;
    }
    p {
      font-size: 0.8rem;
      line-height: 1.5em;
    }
  }
}

// BETA
.beta.home {
  align-items: center;
  background-color: @color-secondary;
  color: @white;
  display: flex;
  justify-content: center;
  padding: 50px 15px;
  position: relative;
  text-align: center;
  .inner {
    margin: 0 auto;
    max-width: 740px;
  }
  h3 {
    .btBlack;
    font-size: 1.6666666667rem;
    line-height: 1.2em;
    margin-bottom: 0.7em;
  }
  p {
    .btLight;
    font-size: 1.4rem;
    line-height: 1.875em;
    margin-bottom: 1em;
  }
  .button {
    font-size: 1rem;
    letter-spacing: 0.1em;
    padding: 19px 42px 17px;
    &:hover {
      padding-left: 70px;
      padding-right: 70px;
    }
  }
}

section.cases.home {
  padding: 50px 0;
  text-align: center;
  .articleMainHead {
    font-size: 0.8888888889rem;
    margin: -10px auto 45px;
  }
  .listing + .button {
    .inline-block();
    margin-top: 50px;
  }
}

.colsWrapper.home {
  .colsOneThird {
    font-size: 0.8888888889rem;
    line-height: 1.65em;
    h2 {
      color: @color-secondary;
      .btBlack;
      font-size: 1.3333333333rem;
      margin-bottom: 0.5em;
    }
    p {
      font-size: 0.9375rem;
    }
    .vacancies {
      line-height: 24px;
      list-style: disc outside none;
      li {
        margin-left: 0.9em;
        padding-left: 0.25em;
      }
    }
  }
}
.colsOneThird {
  // Social
  .home & {
    a {
      color: inherit;
      &.button {
        color: @white;
      }
      span {
        text-decoration: none;
      }
    }
  }

  .followUs {
    li {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .image {
      float: left;
      width: 45px;
      height: 45px;
      overflow: hidden;
      border-radius: 50%;
      img {
        border-radius: 50%;
        .transition(opacity .35s @ease);
        .lt-ie9 & {
          height: 45px !important;
          width: 45px !important;
        }
      }
      a:hover img {
        .opacity(0.9);
      }
    }
    .socialInfo {
      padding: 0 0 0 60px;
    }
  }

  // Werken bij
  .image {
    width: auto;
  }
}

@media (min-width: @breakpointMobileSmall) {
  .info.home {
    p {
      font-size: 1.2222222222rem;
      padding: 50px 30px;
    }
  }

  .update.home {
    .text {
      padding: 50px 30px;
    }
    .image {
      min-height: 500px;
    }
  }

  .beta.home {
    padding: 50px 30px;
    p {
      font-size: 1.7777777778rem;
    }
  }
}

@media (min-width: 500px) {
  section.cases.home {
    .articleMainHead {
      font-size: 1.1111111111rem;
    }
  }
}

@media (min-width: 600px) {
  .update.home {
    .col {
      &.text {
        padding: 45px;
        h2 {
          margin-bottom: 3rem;
        }
      }
      &.image {
        min-height: 600px;
      }
    }
  }

  .info.home {
    min-height: 50vh;
  }

  .beta.home {
    min-height: 60vh;
    padding: 50px 45px;
  }
}

@media (min-width: @breakpointMobile) {
  .front {
    #frontBranding {
      .brandingWrapper {
        padding-right: 30px;
        padding-left: 30px;
        h2 {
          font-size: 1.4rem;
        }
      }
    }
  }

  .update.home {
    align-content: center;
    align-items: center;
    display: flex;
    .col {
      flex-shrink: 0;
      width: 50%;
      .inner {
        margin: 0 auto;
        max-width: 450px;
      }
      &.image {
        align-self: stretch;
      }
    }
    .no-flexbox & {
      .spacing();
      .col {
        .inline-block();
        min-height: 500px;
        vertical-align: top;
        width: 50%;
        .spacing-normal();
      }
    }
  }

  .facts.home {
    max-width: 990px;
    .facts-wrapper {
      div {
        width: ~"calc(33.3333333333% - 30px)";
      }
      p {
        font-size: 0.8888888889rem;
      }
    }
  }
}

@media (min-width: @breakpointMobileLarge) {
  .front {
    #frontBranding {
      .brandingWrapper {
        h2 {
          font-size: 1.6666666667rem;
        }
      }
      .awards {
        bottom: 36px;
        left: 36px;
      }
    }
  }

  .update.home {
    .col {
      &.text {
        padding: 55px;
      }
    }
  }

  .beta.home {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (min-width: 992px) {
  .update.home {
    .col {
      &.text {
        padding: 100px 75px;
      }
      &.image {
        min-height: 600px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .update.home {
    .col {
      &.text {
        padding: 150px 90px;
      }
      &.image {
        min-height: 710px;
      }
    }
  }
}
