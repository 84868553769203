//
// COMPONENTS
//

// BUTTONS
.button {
  background-color: @color-secondary;
  color: @white !important;
  .inline-block();
  font-size: 0.8333333333rem;
  .btBold;
  letter-spacing: 0.1em;
  line-height: 1em;
  overflow: hidden;
  padding: 18px 22px 16px;
  text-decoration: none !important;
  text-transform: uppercase;
  border-radius: 4px;
  .transition(all .35s @ease);
  &:hover {
    background-color: darken(@color-tertiary, 5%);
    color: @color-secondary !important;
    cursor: pointer;
    text-decoration: none !important;
  }
  &.full {
    display: block;
    text-align: center;
  }
  &.small {
    font-size: 0.7222222222rem;
    padding: 13px 16px;
  }
  &.large {
    font-size: 115%;
  }
  &.edit {
    background: url('../images/drupal7-buttons.png') repeat-x 0 0 #e9e9e9;
    border: 1px solid;
    border-color: #e4e4e4 #d2d2d2 #b4b4b4;
    color: #5a5a5a !important;
    cursor: pointer;
    font-family: "Lucida Grande", Verdana, sans-serif;
    font-size: 0.7222222222rem;
    font-weight: normal;
    margin-right: 0.3em;
    padding: 4px 11px;
    text-align: center;
    text-decoration: none !important;
    text-transform: none;
    border-radius: 20px;
    .transition(all .35s @ease);
    &:hover {
      background-position: left -40px;
      border-color: #b4b4b4;
      color: #222222 !important;
      .box-shadow(1px 1px 2px rgba(0,0,0,.2) inset);
    }
  }
  &.secondary {
    background-color: @color-tertiary;
    color: @color-secondary !important;
  }
  &.tertiary {
    background-color: @color-tertiary;
    color: @color-secondary !important;
    padding: 19px 42px 17px;
    .btBlack;
  }
  &.white {
    background-color: @white;
    color: @color-secondary !important;
    &:hover {
      background-color: @color-tertiary;
      color: @color-secondary !important;
    }
  }
}

// SHARE LINKS
.share {
  background: @color-secondary;
  max-width: none !important;
  margin-top: 3em !important;
  .shareLinks {
    list-style: none;
    margin: 10px 0 0;
    padding: 30px 0 10px;
    text-align: center;
    .spacing();
    span.txt {
      color: @color-tertiary;
      display: block;
      font-size: 0.7777777778rem;
      font-weight: normal;
      line-height: 2.1428571429em;
      padding: 0 10px 0 0;
    }
    a span {
      background-image: url("../images/sprite-social-icons.png");
      @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
        background-image: url("../images/sprite-social-icons@2x.png");
        background-size: 240px 30px;
      }
      background-repeat: no-repeat;
      background-position: left center;
      background-color: transparent;
      display: block;
      height: 30px;
      width: 32px;
      .opacity(1);
      .transition(all .35s @ease);
      .lt-ie9 & {
        background-color: @white;
      }
    }
    a:hover span {
      .opacity(0.65);
      .lt-ie8 & {
        cursor: pointer;
      }
    }
    li {
      .inline-block();
      vertical-align: top;
      .spacing-normal();
      &.linkedin span { background-position: left center; }
      &.facebook span { background-position: -30px center; }
      &.twitter span { background-position: -60px center; }
      &.email span { background-position: -90px center; }
    }
  }
}

// SOCIAL LINKS
.socialLinks {
  list-style: none;
  a {
    display: block;
    padding: 4px 0 4px 26px;
    position: relative;
    text-decoration: none;
  }
  li {
    clear: both;
    display: block;
    float: none;
    position: relative;
    span.icon {
      background-image: url("../images/sprite-social-icons.png");
      @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
        background-image: url("../images/sprite-social-icons@2x.png");
        background-size: 240px 30px;
      }
      background-repeat: no-repeat;
      background-position: -120px center;
      background-color: transparent;
      display: block;
      float: left;
      height: 30px;
      left: -6px;
      position: absolute;
      top: -2px;
      width: 30px;
      .opacity(0.3);
      .transition(all .35s @ease);
    }
    &.linkedin span.icon { background-position: left center; }
    &.facebook span.icon { background-position: -30px center; }
    &.twitter span.icon { background-position: -60px center; }
    &.email span.icon { background-position: -90px center; }
    &:hover {
      span.icon {
        .opacity(0.75);
        .lt-ie8 & {
          cursor: pointer;
        }
      }
    }
  }
}

@media (min-width: @breakpointMobile) {
  .share {
    .shareLinks {
      padding: 40px 0 20px;
    }
  }
}
