//
// SLIDERS
//

iframe {
  max-width: 100%;
}

.slider-item {
  display: none;
}

.rsSlide {
  .slider-item {
    display: block;
    .rsCookiePlaceholder {
      background-color: #151515;
      position: relative;
      p {
        font-size: 15px;
        padding: 1rem;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: 0;
        max-width: none;
        width: 100%;
        text-align: center;
        transform: translate(-50%, -50%);
        a {
          color: @white;
          &:hover {
            color: @black;
          }
        }
      }
    }
  }
}

// DEFAULT TOKENSLIDERS
.tokenSlider {
  position: relative;
  .rsDefault {
    background: @white;
    .rsOverflow {
      background: @white;
      .rsSlide {
        background: @white;
      }
    }
    // Bullets
    .rsBullets {
      background: none;
      float: left;
      left: auto;
      position: relative;
      right: 0;
      width: auto;
      .rsBullet {
        padding: 14px 9px 10px;
        span {
          background-color: rgba(0, 0, 0, 0.2);
          .transition(background-color .35s @ease);
        }
        &.rsNavSelected,
        &.rsNavSelected:hover {
          span {
            background-color: @color-secondary;
          }
        }
        &:hover {
          cursor: pointer;
          span {
            background-color: @color-secondary;
          }
        }
      }
    }
    // Caption
    .rsGCaption {
      background: none;
      bottom: 0;
      clear: both;
      color: #666666;
      float: none;
      font-size: 13.5px;
      height: auto;
      left: 0;
      line-height: 1.8em;
      overflow: hidden;
      padding: 5px 8px 0 8px;
      position: relative;
      font-size: 13px;
      border-radius: 0;
      .opacity(0.65);
    }
  }
}

// DEVICE SLIDER
.tokenSlider {
  &.deviceSlider {
    box-sizing: content-box;
    background: @black;
    overflow: hidden;
    z-index: 40;
    border-radius: 4px;
    .sliderWrapper {
      width: 100%;
      .rsDefault {
        background: @black;
        padding: 3% 0 0 3%;
        position: relative;
        width: 97%;
        border-radius: 4px;
        &::after {
          background: url('../royalslider/skins/default/device-overlay.png') right top no-repeat transparent;
          background-size: 50% 100%;
          content: '';
          height: 100%;
          pointer-events: none;
          position: absolute;
          right: 0;
          top: 2px;
          width: 100%;
          z-index: 50;
          .opacity(0.5);
          .lt-ie9 {
            display: none;
          }
        }
        .rsOverflow {
          background: @black;
        }
        .rsContainer {
          background: @black;
        }
        div.rsGCaption {
          color: #cccccc;
          padding: 4px 0 20px;
        }
        div.rsBullets {
          margin-top: 4px;
          .rsBullet {
            span {
              background-color: #666666;
            }
            &:hover {
              span {
                background-color: #999999;
              }
            }
            &.rsNavSelected {
              span {
                background-color: #cccccc;
              }
            }
          }
        }
      }
    }
  }
}
.tokenSlider.deviceSlider.noAfter {
  .rsDefault::after {
    background-size: 0 0;
    z-index:-1;
  }
}
