//
// DRUPAL
//
div {
  &.item-list ul li,
  &.item-list ul {
    margin: 0;
  }
  &.item-list .title {
    font-weight: normal;
  }
}

#infscr-loading {
  div,
  img {
    display: none;
  }
}

// DRUPAL PRIMARY BUTTONS
.tabs ul.tabs.primary,
ul.editBlock {
	border-bottom: none;
	border-collapse: collapse;
	height: auto;
	line-height: 1em;
	list-style: none outside none;
	margin: 15px 0 20px;
	padding: 0;
	white-space: nowrap;
	li {
		a {
			background: url('../images/drupal7-buttons.png') repeat-x 0 0 #e9e9e9;
			border: 1px solid;
			border-color: #e4e4e4 #d2d2d2 #b4b4b4;
			color: #5a5a5a;
			cursor: pointer;
			font-family: "Lucida Grande", Verdana, sans-serif;
			font-size: 13px;
			font-weight: normal;
			margin-right: 0.3em;
			padding: 4px 11px;
			text-align: center;
			text-decoration: none !important;
			border-radius: 20px;
			.transition(all .35s @ease);
			&:hover {
				border-color: #b4b4b4;
				background-position: left -40px;
				color: #222222;
				.box-shadow(1px 1px 2px rgba(0,0,0,.2) inset);
			}
		}
		&.active a,
		&.active a:hover {
			background-position: left -70px;
      border-color: #aaaaaa;
			color: @black;
			.box-shadow(1px 1px 2px rgba(0,0,0,.3) inset);
		}
	}
}

// MESSAGES (OVERRULES DRUPAL STANDARDS)
body div.messages {
	background-position: 6px 6px; /* LTR */
	background-repeat: no-repeat;
	border: none;
	border-bottom: 1px solid @white;
	font-size: 15px;
  line-height: 17px;
  margin: 6px 0 1em;
	padding: 10px 10px 10px 40px; /* LTR */
	text-shadow: 0 1px 0 rgba(0,0,0,0.1);
	border-radius: 5px;
	.box-shadow(0px 2px 2px rgba(0,0,0,0.1) inset);
}
body div.messages a {
  text-decoration: underline;
}

body div.status {
  background-image: url("../images/message-ok.png");
  border-color: @white;
}
body div.messages.status,
body .ok {
  color: @white;
  a, a:hover {
  	color: @white;
	}
}
body div.messages.status,
body table tr.ok {
  background-color: #5c9e45;
}
body div.warning {
  background-image: url("../images/message-warning.png");
  border-color: @white;
}
body div.messages.warning,
body .warning {
  color: #333;
  a, a:hover {
  	color: #333;
	}
  text-shadow: none;
}
body div.messages.warning,
body table tr.warning {
  background-color: #f2d654;
}
body div.messages.error {
  background-image: url("../images/message-warning.png");
  border-color: darken(#f3583e, 25%);
}
body div.messages.error,
body .messages.error {
  color: @white;
}
body div.messages.error,
body table tr.error {
  background-color: #f3583e;
}
body div.error p.error {
  color: @white;
}
body div.messages ul {
  margin: 0 0 0 0; /* LTR */
  padding: 0;
}
body div.messages ul li {
  list-style-image: none;
  pre {
    word-wrap: break-word;
  }
}
#messages {
	clear: left;
  margin: 0 24px;
	.messages.error a {
		color: @white;
		text-decoration: underline;
	}
}

// USER PAGES
.page-user {
  #content {
    padding-top: 90px;
  }
  .tabs,
  #block-system-main {
    margin: 0 auto 20px auto;
    max-width: 750px;
    padding: 0 30px;
  }
  // FORMS
  #user-login,
  #user-pass {
    .form-item {
      margin: 0 0 1.5em 0;
    }
    label {
      font-size: 17px;
      .btBold;
      line-height: 1.3;
    }
    .form-required {
      color: #f3583e;
    }
    input[type="text"],
    input[type="password"] {
      border: 1px solid #e1e1e1;
      color: #333333;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      margin: 0 0 5px 0;
      padding: 0 10px;
      width: 300px;
      border-radius: 0;
      .transition(all .35s @ease);
      &:focus {
        background-color: @focusColor;
        border-color: #c5c5c5;
        outline: 0;
      }
    }
    .form-actions {
      margin: 0;
      input[type="submit"] {
        .button;
        border: none;
        font-size: 16px;
        -webkit-appearance: none;
        -webkit-font-smoothing: antialiased;
      }
    }
  }
}
.page-user- {
  #content {
    padding-top: 70px;
  }
  .profile {
    h3 {
      border-bottom: none;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0.5em;
    }
    dl {
      font-size: 15px;
      margin-bottom: 1em;
      dt {
        font-weight: bold;
      }
    }
  }
}
