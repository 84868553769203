//
// TICKER
//

// TICKER
.ticker {
  background-color: @white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: @color-secondary;
  margin-bottom: @base-line-height;
  overflow: hidden;
  position: relative;
  .ticker-wrapper {
    white-space: nowrap;
    .no-js & {
      overflow: auto;
    }
  }
  .ticker-item {
    .inline-block();
    .btBlack;
    font-size: 10rem;
    line-height: 1em;
    padding: 0.332em 0.3em;
    will-change: transform;
    .translateZ(0);
  }
  .url {
    font-size: 0.8rem;
    left: 50%;
    letter-spacing: 0.1em;
    padding: 14px 32px 12px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    &:hover {
      padding-left: 70px;
      padding-right: 70px;
    }
  }
  // HOME
  &.home {
    background-image: url('../images/bg-ticker.jpg');
    color: @white;
    margin-bottom: 0;
  }
}

@media (min-width: @breakpointMobileSmall) {
  .ticker {
    .ticker-item {
      font-size: 11.5rem;
    }
    .url {
      font-size: 1rem;
      padding: 19px 42px 17px;
    }
  }
}

@media (min-width: 500px) {
  .ticker {
    .ticker-item {
      font-size: 12.5rem;
    }
  }
}

@media (min-width: @breakpointMobile) {
  .ticker {
    .ticker-item {
      font-size: 13.8888888889rem;
    }
  }
}